import React, { useContext, useState } from "react";
import AdminContext from "../../contextAPI/AdminContext";
import { useHistory } from "react-router-dom";

const SkuUploadForm = ({ label }) => {
  const { error, removeError, uploadSkuCSV } = useContext(AdminContext);
  const [uploadFile, setUploadFile] = useState({
    file: "",
    fileLabel: "Choose file",
  });

  const messages = {
    button: {
      modalTitle: "Upload File",
      close: "Close",
      inputGroup: "Upload",
      submit: "Submit",
    },
  };

  const history = useHistory();

  const closeBtnClick = (e) => {
    removeError();
    setUploadFile({
      ...uploadFile,
      file: "",
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { file } = uploadFile;
    // console.log(file);
    uploadSkuCSV(file, history);
  };

  const onFileChange = (e) => {
    let csvFile = e.target.files[0];
    setUploadFile({
      ...uploadFile,
      file: csvFile,
      fileLabel: csvFile.name,
    });
  };

  return (
    <div className="col-md-3 mb-2">
      <button
        type="button"
        className="btn  btn-dark btn-block"
        data-toggle="modal"
        data-target="#exampleModal"
      >
        {label}
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {messages.button.modalTitle}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeBtnClick}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={onSubmit} encType="multipart/form-data">
              <div className="modal-body">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="inputGroupFileAddon01"
                    >
                      {messages.button.inputGroup}
                    </span>
                  </div>
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="inputGroupFile01"
                      aria-describedby="inputGroupFileAddon01"
                      name="files"
                      accept=".csv"
                      required
                      onChange={onFileChange}
                      encType="multipart/form-data"
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="inputGroupFile01"
                    >
                      {uploadFile.fileLabel}
                    </label>
                  </div>
                </div>
              </div>
              <small
                className="text-center"
                style={{
                  color: "red",
                }}
              >
                {error.upload}
              </small>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={closeBtnClick}
                >
                  {messages.button.close}
                </button>
                <input
                  type="submit"
                  value={messages.button.submit}
                  className="btn btn-secondary"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkuUploadForm;
