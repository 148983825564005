import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { isEmpty, isUndefined } from "lodash";

import Navbar from "../utilsComponent/Navbar";
import AdminContext from "../../contextAPI/AdminContext";
import Spinner from "../utilsComponent/Spinner";
import ALerts from "../utilsComponent/ALerts";
import SkuTable from "./SkuTable";
import SkuUploadForm from "./SkuUploadForm";
import PaginationServerSide from "../utilsComponent/PaginationServerSide";
import Search from '../utilsComponent/Search'

const messages = {
  label: {
    show: "Show",
    entries: "entries",
    clientShow: "Search Client",
  },
  heading: {
    text: "SKU",
  },
  button: {
    back: "Home",
    downloadBtn: "Download To CSV",
    uploadBtn: "Upload From CSV",
  },
};

const Sku = () => {
  const {
    getSkus,
    skus,
    loading,
    msg,
    clearMsg,
    getClientName,
    clientname,
    clearSkus,
    clearClientName,
    skuPaginationAndSearchingState,
    skuPagination
  } = useContext(AdminContext);

  let [skuState, setSkuState] = useState({
    searchField: skuPagination?.searchField ? skuPagination?.searchField : "_",
    searchValue: skuPagination?.searchValue ? skuPagination?.searchValue : "",
    page: skuPagination?.page ? skuPagination?.page : 1,
    limit: skuPagination?.limit ? (skuPagination?.limit) : 20,
    serachOption: ["Sku", "Name", "Status"],
    limitOption: [20, 50, "All"],
    clientName: "AllClient",
    clients: [],
    skus: [],
    sortBy: "itemcode",
    allSkusFromCsvReport: [],
    serachSkusNotBySearchValue: true
  });
  const [orderBy, setOrderBy] = useState("asc");

  const setSearchingValues = (value) => {
    skuState = { ...skuState, searchField: value?.searchField, searchValue: value?.searchValue, page: 1, serachSkusNotBySearchValue: false }
    setSkuState({
      ...skuState,
      searchField: value?.searchField,
      searchValue: value?.searchValue,
      page: 1,
      serachSkusNotBySearchValue: false
    })
  }

  const callbackFunctionFromPagination = (page) => {
    setSkuState({
      ...skuState,
      page: parseInt(page),
    });
  };

  const onChange = async (field, value) => {
    setSkuState({
      ...skuState,
      [field]: value,
      page: field === "limit" || field === "clientName" ? 1 : skuState.page,
    });
    if (field == "clientName") {
      localStorage.setItem("currClientNameForFilteringSkus", value);
    }
    if (field === "searchField" && value === "_") {
      skuState.searchField = "";
      skuState.searchValue = "";
      await getSearchValue();
    }
  };

  const currClientNameForFilteringSkus =
    localStorage.currClientNameForFilteringSkus;

  useEffect(async () => {
    if (skuState.searchField == "_") {
     await onChange("searchValue", "");
    }
  }, [skuState.searchField === "_"]);

  useEffect(async () => {
    if (currClientNameForFilteringSkus) {
      setSkuState({
        ...skuState,
        clientName: localStorage.currClientNameForFilteringSkus,
      });
    }
    await getClients();
  }, []);

  useEffect(async () => {
    const { page, limit, searchField, searchValue } = skuState
    await skuPaginationAndSearchingState(page, limit, searchField, searchValue)
  }, [skuState?.page, skuState?.limit, skuState?.searchField, skuState?.searchValue])

  const getClients = async () => {
    let clients = await getClientName();
    setSkuState({
      ...skuState,
      clients: clients,
    });
  };

  const changeOrderBy = () => {
    if (orderBy === "asc") {
      setOrderBy("desc");
    } else {
      setOrderBy("asc");
    }
  };
  useEffect(async () => {
    if (skuState?.serachSkusNotBySearchValue) {
      await getSearchValue();
    }
  }, [
    skuState.limit,
    skuState.page,
    isUndefined(currClientNameForFilteringSkus) && skuState.clients,
    skuState.sortBy,
    orderBy,
    currClientNameForFilteringSkus
  ]);
  const getSearchValue = async (setPageNumberOneIfWeSearchData) => {
    let {
      limit,
      page,
      searchValue,
      searchField,
      clients,
      sortBy,
      clientName,
    } = skuState;
    page = setPageNumberOneIfWeSearchData ? 1 : page;
    setSkuState({
      ...skuState,
      page
    });
    let searchFieldForBackend = "";
    if (searchField === "Sku") {
      searchFieldForBackend = "ITEMCODE";
    }
    if (searchField === "Name") {
      searchFieldForBackend = "ITEMDESCRIPTION";
    }
    if (searchField === "Status") {
      searchFieldForBackend = "ITEMSTATUS";
    }

    if (currClientNameForFilteringSkus) {
      await getSkus(
        limit,
        // searchValue ? (skus.totalPages >= page ? page : 1) : page,
        page,
        currClientNameForFilteringSkus,
        searchFieldForBackend,
        searchValue,
        sortBy,
        orderBy,
        false // getAllSkusFromDBWithoutPagination
      );
    } else if (clients?.clients?.length > 0) {
      await getSkus(
        limit,
        // searchValue ? (skus.totalPages >= page ? page : 1) : page,
        // searchValue ? 1 : page,
        page,
        // clients?.clients[0]?.name,
        clientName,
        searchFieldForBackend,
        searchValue,
        sortBy,
        orderBy,
        false // getAllSkusFromDBWithoutPagination
      );
    }
    skuState = { ...skuState, serachSkusNotBySearchValue: true }
    setSkuState({
      ...skuState,
      serachSkusNotBySearchValue: true
    })
  };

  const downloadAllSkusFromDB = async () => {
    const { limit, page, searchValue, searchField, clients, sortBy } = skuState;
    let searchFieldForBackend = "";
    // server side logic
    if (searchField === "sku") {
      searchFieldForBackend = "ITEMCODE";
    }
    if (searchField === "name") {
      searchFieldForBackend = "ITEMDESCRIPTION";
    }
    if (searchField === "status") {
      searchFieldForBackend = "ITEMSTATUS";
    }
    let allSkusResponse = await getSkus(
      "All",
      1,
      // clients?.clients[0]?.name,
      currClientNameForFilteringSkus || "AllClient",
      searchFieldForBackend,
      searchValue,
      sortBy,
      orderBy,
      true // getAllSkusFromDBWithoutPagination
    );
  };
  const goBackToHome = async () => {
    await skuPaginationAndSearchingState("", skuPagination?.limit, "", "")
    clearSkus();
    clearMsg();
    clearClientName();
    localStorage.removeItem("currClientNameForFilteringSkus");
  }

  let sku = skus.skus || [];


  return (
    <div>
      <Navbar></Navbar>
      <div className="container-fluid mb-4 px-5">
        <h1 className="display-4 ">{messages.heading.text}</h1>
        <Link
          to="/dashboard"
          className="btn btn-primary"
          onClick={async () => {
            await goBackToHome()
          }}
        >
          {messages.button.back}
        </Link>
        {msg && <ALerts msg={msg}> </ALerts>}

        <div className="row mt-2">
          <div className="col-md-3 mb-2">
            <button
              className="btn btn-dark btn-block"
              onClick={async () => {
                await downloadAllSkusFromDB();
              }}
            >
              {messages.button.downloadBtn}
            </button>
          </div>
          <SkuUploadForm label={messages.button.uploadBtn} />

          <Search
            searchForSkus={true}
            getSearchValueFromTable={getSearchValue}
            serachOption={skuState?.serachOption}
            searchField={skuState.searchField}
            searchValue={skuState.searchValue}
            setSearchingValues={setSearchingValues}
          />

        </div>

        {loading && isEmpty(skus) ? (
          <Spinner />
        ) : (
          <div>
            <>
              {loading && <Spinner />}
              <div className="sku-table-limit-search nmt-10">
                <div className="search-name">
                  <p className="pt-3" style={{ fontWeight: "bold" }}>
                    {messages.label.clientShow}
                  </p>
                  <select
                    name="range"
                    className="form-control mx-1 selectList"
                    style={{ width: "120px" }}
                    value={
                      localStorage.currClientNameForFilteringSkus ||
                      (skuState.clientName && skuState.clientName)
                    }
                    onChange={(e) => {
                      onChange("clientName", e.target.value);
                    }}
                  >
                    <option value="AllClient">Search All</option>
                    {!isEmpty(clientname) &&
                      clientname.clients?.map((clientName) => (
                        <option key={clientName._id} value={clientName.name}>
                          {clientName.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="show-limit">
                  <p className="pt-3" style={{ fontWeight: "bold" }}>
                    {messages.label.show}
                  </p>
                  <select
                    name="range"
                    className="form-control mx-1 selectList"
                    style={{ width: "70px" }}
                    onChange={(e) => onChange("limit", e.target.value)}
                    value={skuState?.limit}
                  >
                    {skuState.limitOption.map((limitValue) => (
                      <option key={limitValue} value={limitValue}>
                        {limitValue}
                      </option>
                    ))}
                  </select>
                  <p className="pt-3" style={{ fontWeight: "bold" }}>
                    {messages.label.entries}
                  </p>
                </div>
              </div>

              <div className="skuTable">
                <SkuTable
                  sku={sku}
                  onChange={onChange}
                  changeOrderBy={changeOrderBy}
                />
              </div>
            </>
            <PaginationServerSide
              callBack={callbackFunctionFromPagination}
              total={skus.totalPages}
              initialPage={skuState?.page - 1}

            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Sku;
