import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import AdminState from "./contextAPI/AdminState";
import history from "./history";

ReactDOM.render(
  <React.StrictMode>
    <AdminState>
      <Router history={history}>
        <App> </App>
      </Router>
    </AdminState>
  </React.StrictMode>,
  document.getElementById("root")
);
serviceWorker.unregister();
