import React, { useContext, useEffect } from "react";
import { useHistory,useLocation } from "react-router-dom";
import Navbar from "../../utilsComponent/Navbar";
import { Link } from "react-router-dom";
import AdminContext from "../../../contextAPI/AdminContext";
import AddUserFormContainer from "./AddUserFormContainer";
import { isEmpty } from "lodash";

const AddUser = () => {
  const history = useHistory();
  const location = useLocation();
  const messages = {
    label: {
      goBack: "Go Back",
    },
  };

  const {
    clearCurrentUser,
    currentUser,
    removeError,
    clearMsg,
    currtUser,
    clearClientName,
    getClientName,
    getProductManager,
    clientname,
    productManager,
  } = useContext(AdminContext);

  const onBack = (e) => {
    clearMsg();
    clearCurrentUser();
    removeError();
    clearClientName();
    localStorage.removeItem("currUser");
  };

  const goBackToPrevious = () => {
    console.log("location",location.state);
    history.push({
      pathname: "/users",
    });
    clearMsg();
    clearCurrentUser();
    removeError();
    clearClientName();
    localStorage.removeItem("currUser");
  };

  useEffect(() => {
    const localCurrentUser = localStorage.getItem("currUser");
    if (localCurrentUser) {
      currtUser(JSON.parse(localCurrentUser));
    }
  }, []);
  useEffect(async () => {
    await getProductManager();
    await getClientName();
  }, []);


  return (
    <div style={{ marginBottom: "100px" }}>
      <Navbar></Navbar>
      <div className=" container-fluid mb-4 px-5">
        <h1 className="display-4">
          {JSON.stringify(currentUser) !== JSON.stringify({})
            ? "Manage User"
            : "User"}
        </h1>
        {/* <Link to="/users" className="btn button" onClick={onBack}>
          {messages.label.goBack}
        </Link> */}
        <button className="btn button" onClick={goBackToPrevious}>
          {messages.label.goBack}
        </button>
        <AddUserFormContainer
          productManager={productManager || []}
          clientname={clientname || []}
        />
      </div>
    </div>
  );
};

export default AddUser;
