import {
  ADMIN,
  GET_CLIENTS,
  ADD_CLIENT,
  CURR_CLIENT,
  GET_USERS,
  ADD_USERS,
  CURR_USER,
  GET_ORDERS,
  CURR_ORDER,
  GET_SKU,
  CURR_SKU,
  CURR_CATEGORY,
  ERROR,
  WAREHOUSE,
  SET_LOADING,
  CLIENTNAME,
  PRODUCTMANAGER,
  MSG,
  SET_LOADINGFALSE,
} from "./types";

export default (state, action) => {
  switch (action.type) {
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADINGFALSE:
      return {
        ...state,
        loading: false,
      };
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.payload,
        loading: false,
      };
    case PRODUCTMANAGER:
      return {
        ...state,
        productManager: action.payload,
        loading: false,
      };
    case ADMIN:
      return {
        ...state,
        admin: action.payload,
        auth: true,
      };
    case ADD_CLIENT:
      return {
        ...state,
        client: action.payload,
      };
    case CURR_CLIENT:
      return {
        ...state,
        currentClient: action.payload,
      };
    case CURR_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case ADD_USERS:
      return {
        ...state,
        user: action.payload,
      };
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
        loading: false,
      };
    case CURR_ORDER:
      return {
        ...state,
        currorder: action.payload,
      };
    case GET_SKU:
      return {
        ...state,
        skus: action.payload,
        loading: false,
      };
    case CURR_SKU:
      return {
        ...state,
        sku: action.payload,
      };
    case CURR_CATEGORY:
      return {
        ...state,
        currCategory: action.payload,
      };
    case WAREHOUSE: {
      return {
        ...state,
        warehouse: action.payload,
        loading: false,
      };
    }
    case CLIENTNAME:
      return {
        ...state,
        clientname: action.payload,
      };

    case MSG:
      return {
        ...state,
        msg: action.payload,
      };

    case "CLIENTPAGINATION":
      return {
        ...state,
        clientPagination: action.payload,
      }
    case "USERPAGINATION":
      return {
        ...state,
        userPagination: action.payload,
      }
    case "ORDERPAGINATION":
      return {
        ...state,
        orderPagination: action.payload,
      }
    case "SKUPAGINATION":
      return {
        ...state,
        skuPagination: action.payload,
      }
    default: {
      return state;
    }
  }
};
