import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { allowPostiveNumber } from "../../../common/utilityService";
import { isEmpty } from "lodash";

const UserDetials = ({
  error,
  checkNameisExitsonDB,
  changeInput,
  setClientName,
  clientName,
  login,
  password,
  name,
  organization,
  phone,
  email,
  role,
  address1,
  address2,
  town,
  state,
  postcode,
  clients,
  currentUser,
  userStatus,
  clientNameExits,
  productManager,
}) => {


  

  const [passwordInputState, setPasswordInputState] = useState({
    icon: "fa-eye-slash",
    type: "password",
  });

  const messages = {
    label: {
      userDetails: "User Details",
      client: "Client",
      login: "Login",
      password: "Password",
      role: "Role",
      name: "Name",
      organization: "Organization",
      phone: "Phone",
      email: "Email",
      userStatus: "User Status",
      address1: "Address 1",
      address2: "Address 2",
      town: "Town",
      state: "State",
      postCode: "Post Code",
    },
    option: {
      buyer: "Buyer",
      approver: "Approver",
    },
  };

  useEffect(() => {
    changeInput("role", currentUser.role);
    changeInput("userStatus", userStatus);
  }, []);

  const seePassword = (e) => {
    if (passwordInputState.type === "password") {
      setPasswordInputState({
        ...passwordInputState,
        icon: "fa-eye",
        type: "text",
      });
    } else {
      setPasswordInputState({
        ...passwordInputState,
        icon: "fa-eye-slash",
        type: "password",
      });
    }
  };

  const checkClientNameExitsonOnDB = () => {
    let index = -1;
    index =
      clients.length &&
      !isEmpty(clientName) &&
      clients.findIndex(
        (x) => x.name.toLocaleLowerCase() == clientName.toLocaleLowerCase()
      );
    index = index === -1 ? true : false;
    checkNameisExitsonDB(index);
    return index;
  };

  return (
    <div className="card mb-4 border-dark">
      <div className="card-header text-center adminCardHeader">
        <h5>
          <a
            href="#collapse1"
            data-parent="#accordion"
            data-toggle="collapse"
            className="text-white"
          >
            {messages.label.userDetails}
          </a>
        </h5>
      </div>

      <div id="collapse1" className="collapse show">
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group row ">
                <label htmlFor="name" className="col-lg-4 col-form-label label">
                  {messages.label.client}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <select
                    className={classnames("form-control selectList", {
                      "is-invalid":
                        error?.clientName || checkClientNameExitsonOnDB(),
                    })}
                    onChange={(e) => changeInput("clientName", e.target.value)}
                    value={clientName}
                  >
                    <option value="">Choose Client Name</option>
                    {clients.map((client) => (
                      <option key={client._id} value={client.name}>
                        {client.name}
                      </option>
                    ))}
                  </select>
                  <small>{error?.clientName}</small>
                  <small>
                    {checkClientNameExitsonOnDB() && "ClientName Is Incorrect"}{" "}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group row ">
                <label
                  htmlFor="login"
                  className="col-lg-4 col-form-label label"
                >
                  {messages.label.login}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    className={classnames("form-control ", {
                      "is-invalid": error?.login,
                    })}
                    value={login}
                    onChange={(e) => changeInput("login", e.target.value)}
                  />
                  <small>{error?.login}</small>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group row ">
                <label htmlFor="name" className="col-lg-4 col-form-label label">
                  {messages.label.password}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <div className="input-group mb-3">
                    <input
                      type={passwordInputState.type}
                      className={classnames("form-control ", {
                        "is-invalid": error?.password,
                      })}
                      aria-label="Recipient's username"
                      aria-describedby="show_hide_password"
                      id="password"
                      value={password}
                      onChange={(e) => changeInput("password", e.target.value)}
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text selectList"
                        id="show_hide_password"
                        style={{ cursor: "pointer" }}
                        onClick={seePassword}
                      >
                        <i
                          className={classnames("fa", passwordInputState.icon)}
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                  </div>

                  <small> {error?.password} </small>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group row ">
                <label htmlFor="name" className="col-lg-4 col-form-label label">
                  {messages.label.role}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <select
                    className={classnames("form-control selectList", {
                      "is-invalid": error?.role,
                    })}
                    value={role}
                    onChange={(e) =>
                      changeInput("role", e.target.value.toLowerCase())
                    }
                  >
                    <option value="buyer">{messages.option.buyer}</option>
                    <option value="approver">{messages.option.approver}</option>
                  </select>
                  <small> {error?.role} </small>
                </div>
              </div>
            </div>
            {role == "buyer" ? (
              <div className="col-md-4">
                <div className="form-group row ">
                  <label htmlFor="name" className="col-lg-4 col-form-label">
                    {messages.label.name}
                  </label>
                  <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className={classnames("form-control ", {
                        "is-invalid": error?.name,
                      })}
                      onChange={(e) => changeInput("name", e.target.value)}
                      value={name}
                    />
                    <small> {error?.name} </small>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-4">
                <div className="form-group row ">
                  <label htmlFor="name" className="col-lg-4 col-form-label">
                    {messages.label.name}
                  </label>
                  <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                    <select
                      className={classnames("form-control selectList", {
                        "is-invalid": error?.name,
                      })}
                      onChange={(e) => {
                        
                        changeInput("name", e.target.value)
                      }}
                      value={productManager && productManager.length !==0 ? name : ""}
                    >
                      <option value="">Choose here</option>
                      {productManager &&
                        productManager.map((manager) => (
                          <option key={manager} value={manager}>
                            {manager}
                          </option>
                        ))}
                    </select>
                    <small> {error?.name} </small>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-4">
              <div className="form-group row ">
                <label htmlFor="name" className="col-lg-4 col-form-label">
                  {messages.label.organization}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    className={classnames("form-control ", {
                      "is-invalid": error?.organization,
                    })}
                    value={organization}
                    onChange={(e) =>
                      changeInput("organization", e.target.value)
                    }
                  />
                  <small> {error?.organization} </small>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group row ">
                <label htmlFor="name" className="col-lg-4 col-form-label label">
                  {messages.label.phone}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="number"
                    onKeyDown={allowPostiveNumber}
                    className={classnames("form-control ", {
                      "is-invalid": error?.phone,
                    })}
                    id="phone"
                    onChange={(e) => changeInput("phone", e.target.value)}
                    value={phone}
                  />
                  <small> {error?.phone} </small>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group row ">
                <label htmlFor="name" className="col-lg-4 col-form-label label">
                  {messages.label.email}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="email"
                    className={classnames("form-control   ", {
                      "is-invalid": error?.email,
                    })}
                    onChange={(e) => changeInput("email", e.target.value)}
                    value={email}
                  />
                  <small> {error?.email} </small>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group row">
                <label htmlFor="name" className="col-lg-4 col-form-label label">
                  {messages.label.userStatus}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <select
                    className={classnames("form-control selectList ", {
                      "is-invalid": error?.userstatus,
                    })}
                    value={userStatus}
                    onChange={(e) =>
                      changeInput("status", e.target.value.toLowerCase())
                    }
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                  <small>{error?.userstatus}</small>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group row ">
                <label htmlFor="name" className="col-lg-4 col-form-label label">
                  {messages.label.address1}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    className={classnames("form-control wone", {
                      "is-invalid": error?.address1,
                    })}
                    value={address1}
                    onChange={(e) => changeInput("address1", e.target.value)}
                  />
                  <small> {error?.address1} </small>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group row ">
                <label htmlFor="name" className="col-lg-4 col-form-label label">
                  {messages.label.address2}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    value={address2}
                    onChange={(e) => changeInput("address2", e.target.value)}
                    className={classnames("form-control  wone ", {
                      "is-invalid": error?.address2,
                    })}
                  />
                  <small> {error?.address2} </small>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group row ">
                <label htmlFor="name" className="col-lg-4 col-form-label label">
                  {messages.label.town}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    className={classnames("form-control ", {
                      "is-invalid": error?.town,
                    })}
                    value={town}
                    onChange={(e) => changeInput("town", e.target.value)}
                  />
                  <small> {error?.town} </small>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group row ">
                <label htmlFor="name" className="col-lg-4 col-form-label label">
                  {messages.label.state}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    className={classnames("form-control ", {
                      "is-invalid": error?.state,
                    })}
                    value={state}
                    onChange={(e) => changeInput("state", e.target.value)}
                  />
                  <small> {error?.state} </small>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group row ">
                <label htmlFor="name" className="col-lg-4 col-form-label label">
                  {messages.label.postCode}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="number"
                    className={classnames("form-control ", {
                      "is-invalid": error?.postcode,
                    })}
                    onKeyDown={allowPostiveNumber}
                    value={postcode}
                    onChange={(e) => changeInput("postcode", e.target.value)}
                  />
                  <small> {error?.postcode} </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetials;
