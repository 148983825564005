import React, { useContext, useState } from "react";
import AdminContext from "../../contextAPI/AdminContext";

const UserUploadForm = ({ history }) => {
  const { error, removeError, uploadUserCSV } = useContext(AdminContext);
  const [uploadFileState, setUploadFileState] = useState({
    file: "",
    label: "Choose file",
  });

  const messages = {
    label: {
      upload: "Upload",
    },
    button: {
      uploadCsv: "Upload From CSV",
      close: "Close",
    },
  };

  const onSubmit = (e) => {
    e.preventDefault();
    uploadUserCSV(uploadFileState.file, history);
  };

  const onFileChange = (e) => {
    let csvFile = e.target.files[0];
    if (csvFile) {
      setUploadFileState({
        ...uploadFileState,
        file: csvFile,
        label: csvFile.name,
      });
    }
  };

  const onClose = () => {
    removeError();
    setUploadFileState({
      ...uploadFileState,
      file: "",
      label: "Choose file",
    });
  };

  return (
    <div className="col-md-4 mb-2">
      <button
        type="button"
        className="btn button btn-block"
        data-toggle="modal"
        data-target="#exampleModal"
      >
        {messages.button.uploadCsv}
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {messages.label.upload}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form onSubmit={onSubmit} encType="multipart/form-data">
              <div className="modal-body">
                <div className="custom-file-border">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text"
                        id="inputGroupFileAddon01"
                      >
                        {messages.label.upload}
                      </span>
                    </div>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        aria-describedby="inputGroupFileAddon01"
                        name="files"
                        onChange={onFileChange}
                        encType="multipart/form-data"
                        accept=".csv"
                        required
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="inputGroupFile01"
                      >
                        {uploadFileState.label}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <small
                className="text-center"
                style={{
                  color: "red",
                }}
              >
                {error.upload}
              </small>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn button"
                  data-dismiss="modal"
                  onClick={onClose}
                >
                  {messages.button.close}
                </button>
                <input type="submit" value="Submit" className="btn button" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserUploadForm;
