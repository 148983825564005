import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../utilsComponent/Navbar";
import AdminContext from "../../contextAPI/AdminContext";
import ALerts from "../utilsComponent/ALerts";
import nvn from "../../assets/images/nvn.png";

const AdminDashboard = () => {
  const { msg, clearMsg, setCurrentUser, clearAllPaginationState } = useContext(AdminContext);
  const messages = {
    heading: {
      main: "What Would You Like To Do?",
    },
  };

  useEffect(async () => {
    await clearAllPaginationState()
  }, [])

  return (
    <div className="admin-dashboard">
      <Navbar></Navbar>
      <div className="admin-header-img">
        <img
          src={nvn}
          alt=""
        />
      </div>
      <div className="container">
        {/* <h3 className="text-center mb-4">{messages.heading.main} </h3> */}
        {msg && <ALerts msg={msg}></ALerts>}
        <div className="admin-actions">
          <div className="row">
            <div className="col-md-3">
              <Link
                className="btn button btn-block mb-3 py-5 px-2"
                to="/clients"
                onClick={clearMsg}
              >
                <i className="fas fa-user fa-5x"></i>
                <br />
                Maintain Clients
              </Link>
            </div>
            <div className="col-md-3">
              <Link
                className="btn button btn-block mb-3 py-5 px-2"
                to="/users"
                onClick={clearMsg}
              >
                <i className="fa fa-user fa-5x" aria-hidden="true"></i>
                <br />
                Maintain Users
              </Link>
            </div>

            <div className="col-md-3">
              <Link
                className="btn button btn-block mb-3 py-5 px-2"
                to="/orders"
                onClick={clearMsg}
              >
                <i className="fa fa-check-circle fa-5x" aria-hidden="true"></i>
                <br />
                Review Orders
              </Link>
            </div>

            <div className="col-md-3">
              <Link
                className="btn button btn-block mb-3 py-5 px-2"
                to="/sku"
                onClick={clearMsg}
              >
                <i className="fa fa-sticky-note fa-5x " aria-hidden="true"></i>
                <br />
                Maintain SKUs
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
