import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import Navbar from "../../utilsComponent/Navbar";
import { Link, useHistory, useLocation } from "react-router-dom";
import AdminContext from "../../../contextAPI/AdminContext";
import EditSkuForm from "./EditSkuForm";
const EditSku = () => {
  const history = useHistory();
  const {state} = useLocation();


  const {
    updateSku,
    getAllclients,
    currCategory,
    clearCategory,
    clearMsg,
  } = useContext(AdminContext);

  // const [updateCategories, setUpdateCategories] = useState([]);
  const [editSkuState, setEditSkuState] = useState({
    sku: {},
    pricingMode: {},
    option: [],
    updateCategories: currCategory.selectedCategories || [],
    unitPriceDisable: false,
    isAllCategoriesDeleted: false,
  });

  const messages = {
    heading: "Review SKU",
    button: {
      exit: "Go Back",
    },
  };

  const getPricingMode = () => {
    const localSku = localStorage.getItem("sku");
    if (localSku) {
      let sku = JSON.parse(localSku);
      if (sku && sku.priceMode) {
        if (sku.priceMode === "pricingformfeed") {
          return "Pricing From Feed";
        } else if (sku.priceMode === "maintainPricing") {
          return "Maintain Pricing";
        } else if (sku.priceMode === "nopricing") {
          return "No Pricing";
        } else {
          return "";
        }
      }
    }
  };

  const getUnitPriceBoolean = () => {
    const localSku = localStorage.getItem("sku");

    if (localSku) {
      let sku = JSON.parse(localSku);
      if (sku && sku.priceMode) {
        if (sku.priceMode === "pricingformfeed") {
          return true;
        } else if (sku.priceMode === "maintainPricing") {
          return false;
        } else if (sku.priceMode === "nopricing") {
          return true;
        }
      }
    }
  };

  useEffect(() => {
    // getAllclients();
    const localSkus = JSON.parse(localStorage.getItem("sku"));
    const localAllCategories = JSON.parse(
      localStorage.getItem("allCurrCategory")
    );


    setEditSkuState({
      ...editSkuState,
      sku: localSkus || {},
      option: localAllCategories || [],
      pricingMode: getPricingMode(),
      unitPriceDisable: getUnitPriceBoolean(),
    });

    return;
  }, [currCategory]);

  const selctedCategories = (categories) => {
    setEditSkuState({
      ...editSkuState,
      updateCategories: categories,
      isAllCategoriesDeleted: !categories?.length ? true : false,
    });
  };

  const updateAndSubmit = (e) => {
    e.preventDefault();
    const localSkus = JSON.parse(localStorage.getItem("sku"));

    clearCategory();
    if (editSkuState.sku) {
      let updateCurrentCategories = [];
      if (
        editSkuState.updateCategories.length ||
        editSkuState.isAllCategoriesDeleted
      ) {
        updateCurrentCategories = editSkuState.updateCategories;
      } else {
        updateCurrentCategories = editSkuState.option.selectedCategories;
      }
      const categoriesIds = updateCurrentCategories.map(
        (category) => category._id
      );
      updateSku(history, { sku: editSkuState.sku, unitPriceOfSkuForOrder: localSkus.unitPriceForCreatingAnOrder, categoriesIds },state);
    }
  };

  const goBack = () => {
    history.push({
      pathname: "/sku",
    }); 
    localStorage.removeItem("sku");
    localStorage.removeItem("categories");
    localStorage.removeItem("allCurrCategory");
    localStorage.removeItem("currClient");
    clearCategory();
    clearMsg();
  };

  return (
    <>
      <Navbar></Navbar>
      <div className="container-fluid mb-4 px-5">
        <h1 className="display-3">{messages.heading}</h1>
        <button className="btn btn-primary mb-3" onClick={goBack}>
          {messages.button.exit}
        </button>

        <EditSkuForm
          updateAndSubmit={updateAndSubmit}
          selctedCategories={selctedCategories}
          pricingMode={editSkuState.pricingMode}
          option={editSkuState.option.allCategories}
          sku={editSkuState.sku}
          unitPriceDisable={editSkuState.unitPriceDisable}
          currCategory={editSkuState.option.selectedCategories}
          roundingNumber={editSkuState.option.roundingNumber}
        />
      </div>
    </>
  );
};

export default EditSku;
