import React, { useContext, useState, useEffect } from "react";
import AdminContext from "../../contextAPI/AdminContext";
import { useHistory } from "react-router-dom";
import ALerts from "../utilsComponent/ALerts";
import Label from "../utilsComponent/Label";
import Input from "../utilsComponent/Input";
import InputBtn from "../utilsComponent/InputBtn";

const Login = () => {
  const history = useHistory();
  const { error, superAdminLogin, msg, setAdminLogin } = useContext(
    AdminContext
  );
  const [loginState, setLoginState] = useState({
    email: "",
    password: "",
  });

  const message = {
    headerMsg: "MODERN PROMOTIONS WAREHOUSING WEB PORTAL ADMIN",
    accountMsg: "ACCOUNT LOG IN",
  };

  const changeLoginState = (field, value) => {
    setLoginState({
      ...loginState,
      [field]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    superAdminLogin(loginState.email, loginState.password, history);
  };

  useEffect(() => {
    if (localStorage.jwtToken) {
      setAdminLogin(localStorage.getItem("jwtToken"), history);
    }
  }, [error, msg]);

  return (
    <div className="admin-login">
      <header className="admin-header text-dark mb-5 AkkuratBold ">
        <h4 className="text-center container p-3 ">{message.headerMsg}</h4>
      </header>
      <div className="container">
        <h1 className="display-3 text-center mb-4">{message.accountMsg}</h1>
        {msg && <ALerts msg={msg}></ALerts>}
        <form
          onSubmit={onSubmit}
          className="border p-5 "
          style={{ marginTop: "100px" }}
        >
          <div className="form-group row ">
            <Label
              className="col-sm-2 col-form-label label"
              htmlFor="Email"
              msg="Login"
            ></Label>
            <div className="col-sm-10  px-0 px-md-3 px-sm-3">
              <Input
                type="email"
                name="email"
                error={error?.email}
                callBack={(e) => {
                  changeLoginState("email", e);
                }}
              ></Input>
            </div>
          </div>

          <div className="form-group row ">
            <Label
              className="col-sm-2 col-form-label label"
              htmlFor="password"
              msg="Password"
            ></Label>
            <div className="col-sm-10 px-0 px-md-3 px-sm-3">
              <Input
                type="password"
                name="password"
                error={error?.password}
                callBack={(e) => {
                  changeLoginState("password", e);
                }}
              ></Input>
            </div>
          </div>

          <InputBtn
            type="submit"
            value="LOGIN"
            className="btn button float-right"
          ></InputBtn>
        </form>
      </div>
    </div>
  );
};

export default Login;
