import React, { useReducer, useEffect } from "react";
import fileDownload from "js-file-download";
import AdminContext from "./AdminContext";
import axios from "axios";
import setAuthTokenAdmin from "./setAuthTokenAdmin";
import jwtDecode from "jwt-decode";
import {
  GET_CLIENTS,
  ADMIN,
  ADD_CLIENT,
  CURR_CLIENT,
  GET_USERS,
  ADD_USERS,
  CURR_USER,
  GET_ORDERS,
  CURR_ORDER,
  GET_SKU,
  CURR_CATEGORY,
  ERROR,
  WAREHOUSE,
  SET_LOADING,
  CLIENTNAME,
  PRODUCTMANAGER,
  MSG,
  CURR_SKU,
  SET_LOADINGFALSE,
  GET_ALL_SKU,
} from "./types";
import AdminReducer from "./AdminReducer";

const AdminState = (props) => {
  const initialState = {
    error: {},
    clients: [],
    users: [],
    user: {},
    currentUser: {},
    client: {},
    admin: {},
    currentClient: {},
    auth: false,
    orders: { orders: [], totalPages: 1 },
    currorder: {},
    skus: [],
    currsku: {},
    currCategory: [],
    productManager: [],
    warehouse: [],
    clientname: {},
    loading: false,
    msg: "",
    clientPagination: {},
    userPagination: {},
    orderPagination: {},
    skuPagination: {}
  };
  const [state, dispatch] = useReducer(AdminReducer, initialState);
  useEffect(() => {
    if (localStorage.getItem("jwtToken")) {
      setAuthTokenAdmin(localStorage.jwtToken);
    }
  }, []);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const configUpload = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        401 === error.response?.status &&
        error.response.data === "Unauthorized"
      ) {
        localStorage.removeItem("jwtToken");
        setMsg("you are logged out");
        setAuthTokenAdmin(false);
        clearAdmin();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        return Promise.reject(error);
      }
    }
  );
  /**
   *
   * @param {*} Admin
   */

  const superAdminLogin = async (email, password, history) => {
    const adminData = {
      email: email,
      password: password,
    };
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/login`,
        adminData,
        config
      );
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      setAuthTokenAdmin(token);
      const decode = jwtDecode(token);

      History(history, "/dashboard");
      setMsg("Admin you are logged in");
      removeError();
      timeOut();
    } catch (err) {
      Dispatch(ERROR, err?.response?.data?.err);
      History(history, "/login");
    }
  };
  const setCurrentUser = async () => {
    if (localStorage.jwtToken) {
      setAuthTokenAdmin(localStorage.jwtToken);
      try {
        let res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/dashboard`
        );
        Dispatch(ADMIN, res.data);
      } catch (err) {
        // console.log(err.response);
      }
    }
  };
  const clearAdmin = () => {
    Dispatch(ADMIN, {});
  };
  const setAdminLogin = async (token, history) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/find`,
        { token },
        config
      );
      History(history, "/dashboard");
    } catch (err) {
      console.log(err, "error");
    }
  };

  /**
   *
   * @param {*} Client
   */

  const getAllclients = async (limit, page) => {
    if (localStorage.jwtToken) {
      setAuthTokenAdmin(localStorage.jwtToken);
      setLoading();
      let clientResponse;
      clientResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/client/allclients/?limit=${limit}&page=${page}`,
        config
      );
      Dispatch(GET_CLIENTS, clientResponse.data);
    }
  };
  const clearAllClients = () => {
    Dispatch(GET_CLIENTS, []);
  };
  const addClient = async (
    clientData,
    history,
    categoryArray,
    categoriesImages,
    location
  ) => {
    if (localStorage.jwtToken) {
      setAuthTokenAdmin(localStorage.jwtToken);
      try {
        let clientResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/client/createClient`,
          clientData
        );
        if (categoryArray?.length) {
          await addNewCategories(
            categoryArray,
            categoriesImages,
            clientResponse
          );
        }

        removeError();
        setMsg("new client has been added");
        History(history, "/clients", {
          page: location?.state?.page ? location?.state?.page : 1,
          limit: location?.state?.limit ? location?.state?.limit : 20
        });
        Dispatch(ADD_CLIENT, clientResponse.data.data);
        timeOut();
      } catch (err) {
        Dispatch(ERROR, err?.response?.data?.err);
      }
    }
  };
  const currClient = async (data) => {
    // if (localStorage.jwtToken) {
    //   setAuthTokenAdmin(localStorage.jwtToken);
    //   let clientResponse = await axios.get(
    //     `${process.env.REACT_APP_BACKEND_URL}/client/getClientById/${data._id}`,
    //   );
    //   Dispatch(CURR_CLIENT, clientResponse?.data?.data);
    //   const categortResponse = await currentCategory(data._id);
    //   Dispatch(CURR_CATEGORY, categortResponse.data.data);
    // }

    if (JSON.stringify(data) !== JSON.stringify({})) {
      localStorage.setItem("currClient", JSON.stringify(data));
      Dispatch(CURR_CLIENT, data);
      const categortResponse = await currentCategory(data._id);
      localStorage.setItem("currCategory", JSON.stringify(categortResponse.data.data));
      Dispatch(CURR_CATEGORY, categortResponse.data.data);

    }
  };

  const clearCurrentClient = () => {
    Dispatch(CURR_CLIENT, {});
  };
  const updateClient = async (
    clientData,
    id,
    history,
    categoryArray,
    categoriesImages,
    location
  ) => {
    if (localStorage.jwtToken) {
      setAuthTokenAdmin(localStorage.jwtToken);
      try {
        let clientResponse = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/client/updateClient/${id}`,
          clientData
        );

        let oldCategoryArray = categoryArray.filter((cat) => cat._id);
        let newCategoryArray = categoryArray.filter((cat) => !cat._id);
        let newAddedCategories = [];
        if (newCategoryArray?.length) {
          newAddedCategories = await addNewCategories(
            newCategoryArray,
            categoriesImages,
            clientResponse
          );
        }
        const selectedCategories = oldCategoryArray.concat(newAddedCategories);
        const selectedCategoriesIds = selectedCategories.map((cat) => cat._id);

        await updateCategories(
          { selectedCategoriesIds: selectedCategoriesIds },
          clientResponse.data.data._id
        );

        removeError();
        setMsg("client has been updated");
        History(history, "/clients", {
          addclientmsg: "client has been updated",
          page: location?.state?.page ? location?.state?.page : 1,
          limit: location?.state?.limit ? location?.state?.limit : 20
        });
        localStorage.removeItem("currClient");
        localStorage.removeItem("currCategory");
        Dispatch(ADD_CLIENT, clientResponse.data.data);
        timeOut();
      } catch (err) {
        console.log("ERR", err);
        Dispatch(ERROR, err?.response?.data?.err);
      }
    }
  };
  const addNewCategories = async (
    categoryArray,
    categoriesImages,
    clientResponse
  ) => {
    const categoriesFormData = new FormData();
    categoryArray.map((category) => {
      category.clientId = clientResponse.data.data._id;
      return category;
    });
    for (const image of categoriesImages) {
      categoriesFormData.append("images", image);
    }
    let categories = JSON.stringify(categoryArray);
    categoriesFormData.append("categories", categories);
    return await addCategories(categoriesFormData);
  };
  const getClientNameFromOstendo = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/client/getAllClientNamesFromOstendo`
      );
      Dispatch(CLIENTNAME, res.data.data);
    } catch (err) { }
  };

  const getClientName = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/client/getAllClientNames`
      );
      Dispatch(CLIENTNAME, res.data.data);
      return res.data.data;
    } catch (err) { }
  };
  const getProductManager = async () => {
    if (localStorage.jwtToken) {
      setAuthTokenAdmin(localStorage.jwtToken);
      try {
        setLoading();
        let res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/user/getProductManager`,
          config
        );
        Dispatch(PRODUCTMANAGER, res.data.data);
      } catch (err) { }
    }
  };
  const clearClientName = () => {
    Dispatch(CLIENTNAME, []);
  };
  const clientName = async (skuId, clientName) => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/sku/getSkuCategories?id=${skuId}&clientName=${clientName}`
      );

      localStorage.setItem("allCurrCategory", JSON.stringify(res.data.data));
      Dispatch(CURR_CATEGORY, res.data.data);
    } catch (err) { }
  };
  const maptoWhereHouse = async () => {
    setLoading();
    await getClientNameFromOstendo();
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/client/getAllWarehouse`
      );
      Dispatch(WAREHOUSE, res.data.data);
    } catch (err) { }
  };

  /**
   *
   * @param {*}  User
   */

  const getAllUsers = async (
    limit,
    page,
    searchField,
    searchValue,
    sortBy,
    orderBy
  ) => {
    if (localStorage.jwtToken) {
      setAuthTokenAdmin(localStorage.jwtToken);
      try {
        setLoading();
        let res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/user/getallusers/?limit=${limit}&page=${page}&searchField=${searchField}&searchValue=${searchValue}&sortBy=${sortBy}&orderBy=${orderBy}`,
          config
        );
        Dispatch(GET_USERS, res.data);
      } catch (err) { }
    }
  };
  const clearAllUsers = () => {
    Dispatch(GET_USERS, []);
  };
  const addUsers = async (userData, history, location) => {
    if (localStorage.jwtToken) {
      setAuthTokenAdmin(localStorage.jwtToken);
      try {
        let res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/user/adduser`,
          userData,
          config
        );

        removeError();
        setMsg("new user has been added");
        clearCurrentUser();
        // History(history, "/users", { reload: false });
        history.push({
          pathname: "/users",
          state: {
            page: location?.state?.page || 1, limit: location?.state?.limit || 20,
            searchField: location?.state?.searchField || "",
            searchValue: location?.state?.searchValue || "",
            reload: false
          },
        });
        clearClientName();
        Dispatch(ADD_USERS, res.data.data);
        timeOut();
      } catch (err) {
        Dispatch(ERROR, err?.response?.data?.err);
      }
    }
  };
  const currtUser = (data) => {
    if (JSON.stringify(data) !== JSON.stringify({})) {
      localStorage.setItem("currUser", JSON.stringify(data));
      Dispatch(CURR_USER, JSON.parse(localStorage.getItem("currUser")));
    }
  };
  const clearCurrentUser = () => {
    Dispatch(CURR_USER, {});
  };
  const updateUser = async (data, userid, history, location) => {
    if (localStorage.jwtToken) {
      setAuthTokenAdmin(localStorage.jwtToken);
      try {
        let res = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/user/${userid}`,
          data,
          config
        );
        localStorage.removeItem("currUser");
        removeError();
        setMsg(" user has been updated");
        clearCurrentUser();
        clearClientName();
        // History(history, "/users");
        history.push({
          pathname: "/users",
          state: {
            page: location?.state?.page || 1, limit: location?.state?.limit || 20,
            searchField: location?.state?.searchField || "",
            searchValue: location?.state?.searchValue || ""
          },
        })
        Dispatch(ADD_USERS, res.data.data);
        timeOut();
      } catch (err) {
        Dispatch(ERROR, err?.response?.data?.err);
      }
    }
  };

  /**
   *
   * @param {*}  Order
   */

  const getOrders = async (
    limit,
    page,
    searchField,
    searchValue,
    from,
    to,
    sortBy,
    orderBy
  ) => {
    setLoading();
    if (localStorage.jwtToken) {
      setAuthTokenAdmin(localStorage.jwtToken);
      try {
        let res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/order/getall/?limit=${limit}&page=${page}&searchField=${searchField}&searchValue=${searchValue}&dateFrom=${from}&dateTo=${to}&sortBy=${sortBy}&orderBy=${orderBy}`,
          config
        );
        Dispatch(GET_ORDERS, res.data.data);
      } catch (err) { }
    }
  };
  const currOrder = (order) => {
    localStorage.setItem("currOrder", JSON.stringify(order));
    Dispatch(CURR_ORDER, order);
  };
  const updateOrder = async (data, id, history, location) => {
    try {
      if (localStorage.jwtToken) {
        setAuthTokenAdmin(localStorage.jwtToken);
      }
      setLoading();
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/order/updateOrderByAdmin/${id}`,
        data,
        config
      );
      state.loading = false;
      // History(history, "/orders");
      history.push({
        pathname: "/orders", state: {
          page: location?.state?.page || 1, limit: location?.state?.limit || 20,
          searchField: location?.state?.searchField || "",
          searchValue: location?.state?.searchValue || "",
        }
      });
      setMsg("Order is Updated successfully!!");
      clearCurrentOrder();
      timeOut();
    } catch (err) { }
  };

  const syncOrder = async (id) => {
    try {
      if (localStorage.jwtToken) {
        setAuthTokenAdmin(localStorage.jwtToken);
      }
      setLoading();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/order/syncOrder/${id}`
      );
      setLoadingFalse();
      setMsg("Order is Sync successfully!!");
      timeOut();
    } catch (err) {
      setLoadingFalse();
      setMsg("Order is Not Sync successfully!!");
    }
  };

  const clearAllOrder = () => {
    Dispatch(GET_ORDERS, []);
  };
  const clearCurrentOrder = () => {
    localStorage.removeItem("currOrder");
    Dispatch(CURR_ORDER, []);
  };

  /**
   *
   * @param {*}  SKU
   */

  const getSkus = async (
    limit,
    page,
    clientName,
    searchField,
    searchValue,
    sortBy,
    orderBy,
    getAllSkusFromDBWithoutPagination
  ) => {
    setLoading();
    if (localStorage.jwtToken) {
      setAuthTokenAdmin(localStorage.jwtToken);
      //  console.log(getAllSkusFromDBWithoutPagination);
      try {
        if (!getAllSkusFromDBWithoutPagination) {
          let res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/sku/getall/?limit=${limit}&page=${page}&clientName=${clientName}&searchField=${searchField}&searchValue=${searchValue}&sortBy=${sortBy}&orderBy=${orderBy}`,
            config
          );
          // console.log("res.data", res.data);
          Dispatch(GET_SKU, res.data);
        } else {
          let res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/sku/downloadAllSku/?limit=${limit}&page=${page}&clientName=${clientName}&searchField=${searchField}&searchValue=${searchValue}&sortBy=${sortBy}&orderBy=${orderBy}`,
            {
              headers: {
                "Content-Type": "application/json",
                responseType: "blob",
              },
            }
          );
          fileDownload(res.data, "sku.csv");
          setLoadingFalse();
        }
      } catch (err) { }
    }
  };

  const updateSku = async (history, data, locationState) => {
    if (localStorage.jwtToken) {
      setAuthTokenAdmin(localStorage.jwtToken);
      try {
        await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/sku/updateSkuCategories`,
          data,
          config
        );
        setMsg("sku is updated Successfully!!");
        // History(history, "/sku");
        history.push({
          pathname: "/sku", state: {
            page: locationState?.page || 1, limit: locationState?.limit || 20,
            searchField: locationState?.searchField || "",
            searchValue: locationState?.searchValue || "",
          }
        });
        localStorage.removeItem("sku");
        localStorage.removeItem("allCurrCategory");
        localStorage.removeItem("currClient");
        timeOut();
      } catch (error) {
        Dispatch(ERROR, error?.response?.data?.err);
      }
    }
  };
  const clearSkus = () => {
    Dispatch(GET_SKU, []);
  };
  /**
   *
   * @param {*}  Categories
   */

  const addCategories = async (categoriesData) => {
    try {
      let addCategoriesResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/category/addNewCategories`,
        categoriesData
      );
      return addCategoriesResponse.data.data;
    } catch (err) {
      Dispatch(ERROR, err?.response?.data?.err);
    }
  };
  const uploadCategoryPhoto = async (formDatas) => {
    try {
      let data = new FormData();
      for (let i = 0; i < formDatas.length; i++) {
        data.append("files", formDatas[i]);

        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/category/uplaodImage`,
          data,
          configUpload
        );
      }
    } catch (err) { }
  };
  const currentCategory = async (id) => {
    try {
      // let res = await axios.get(
      //   `${process.env.REACT_APP_BACKEND_URL}/category/get/${id}`
      // );
      // Dispatch(CURR_CATEGORY, res.data.data);
      const skipPagination = true;
      return await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/category/get/${id}?skipPagination=${skipPagination}`
      );
    } catch (err) { }
  };
  const clearCategory = () => {
    Dispatch(CURR_CATEGORY, []);
  };
  const updateCategories = async (categoryArray, id) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/category/update/${id}`,
        categoryArray,
        config
      );
    } catch (err) { }
  };
  const clientCategories = async (id) => {
    try {
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/category/get/${id}`
      );
    } catch (err) { }
  };

  /**
   *
   * @param {*}  Ostendo
   */

  /**
   *
   * @param {*}  CSV
   */
  const uploadClientCSV = async (formData, history) => {
    if (localStorage.jwtToken) {
      setAuthTokenAdmin(localStorage.jwtToken);
      try {
        let res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/csv/uploads/client`,
          formData,
          configUpload
        );
        if (res.data.data !== undefined) {
          window.location.reload();
        } else {
          Dispatch(ERROR, { upload: "error for uplaoding" });
        }
        History(history, "/clients");
      } catch (err) {
        Dispatch(ERROR, { upload: "error for uplaoding" });
      }
    }
  };
  const uploadUserCSV = async (file, history) => {
    if (localStorage.jwtToken) {
      setAuthTokenAdmin(localStorage.jwtToken);
      try {
        const formData = new FormData();
        formData.append("files", file);
        let res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/csv/uploads/users`,
          formData,
          configUpload
        );
        if (res.data.data !== undefined) {
          window.location.reload();
        } else {
          Dispatch(ERROR, { upload: "error for uplaoding" });
        }
      } catch (err) {
        Dispatch(ERROR, { upload: "error for uplaoding" });
      }
    }
  };
  const uploadSkuCSV = async (file, history) => {
    if (localStorage.jwtToken) {
      setAuthTokenAdmin(localStorage.jwtToken);
      try {
        const formData = new FormData();
        formData.append("files", file);
        let res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/csv/uploads/sku`,
          formData,
          configUpload
        );
        if (res.data.data !== undefined) {
          window.location.reload();
        } else {
          Dispatch(ERROR, { upload: "error for uplaoding" });
        }
      } catch (err) {
        Dispatch(ERROR, { upload: "error for uplaoding" });
      }
    }
  };

  /**
   *
   * @param {*}  Utils
   */

  const removeError = () => {
    Dispatch(ERROR, {});
  };
  const setLoading = () => {
    Dispatch(SET_LOADING);
  };
  const setLoadingFalse = () => {
    Dispatch(SET_LOADINGFALSE);
  };
  const setMsg = (msg) => {
    Dispatch(MSG, msg);
  };
  const clearMsg = () => {
    Dispatch(MSG, "");
  };
  const History = (history, pathname, msg) => {
    history.push({
      pathname: pathname,
      state:
        JSON.stringify(msg) !== JSON.stringify({}) && msg !== undefined
          ? msg
          : "",
    });
  };
  const Dispatch = (type, data) => {
    dispatch({
      type: type,
      payload: data,
    });
  };
  const timeOut = () => {
    setTimeout(() => {
      clearMsg();
    }, 10000);
  };
  const clearAllState = () => {
    Dispatch(MSG, "");
    Dispatch(ERROR, "");
    Dispatch(CURR_CATEGORY, "");
    Dispatch(CURR_SKU, "");
    Dispatch(CURR_ORDER, "");
    Dispatch(CURR_USER, "");
    Dispatch(CURR_CLIENT, "");
    localStorage.removeItem("currClientNameForFilteringSkus");
    Dispatch("USERPAGINATION", { ...state.userPagination,searchField:"", searchValue:"",page:""})
    Dispatch("CLIENTPAGINATION", { ...state?.clientPagination,page:""})
    Dispatch("ORDERPAGINATION", { ...state.orderPagination,searchField:"", searchValue:"",page:""})
    Dispatch("SKUPAGINATION", { ...state.skuPagination,searchField:"", searchValue:"",page:""})
  };


  const clearAllPaginationState = ()=>{
    Dispatch("USERPAGINATION", { ...state.userPagination,searchField:"", searchValue:"",page:""})
    Dispatch("CLIENTPAGINATION", { ...state?.clientPagination,page:""})
    Dispatch("ORDERPAGINATION", { ...state.orderPagination,searchField:"", searchValue:"",page:"",from:"",to:""})
    Dispatch("SKUPAGINATION", { ...state.skuPagination,searchField:"", searchValue:"",page:""})
  }

  const clientPaginationAndSearchingState = async (page, limit) => {
    Dispatch("CLIENTPAGINATION", { page, limit })
  }

  const userPaginationAndSearchingState = async (page, limit, searchField, searchValue) => {
    Dispatch("USERPAGINATION", { page, limit, searchField, searchValue })
  }

  const orderPaginationAndSearchingState = async (page, limit, searchField, searchValue,from,to) => {
    Dispatch("ORDERPAGINATION", { page, limit, searchField, searchValue,from,to })
  }

  const skuPaginationAndSearchingState = async (page, limit, searchField, searchValue) => {
    Dispatch("SKUPAGINATION", { page, limit, searchField, searchValue })
  }
  return (
    <AdminContext.Provider
      value={{
        superAdminLogin,
        getProductManager,
        getAllclients,
        setCurrentUser,
        addClient,
        currClient,
        updateClient,
        getAllUsers,
        addUsers,
        clearCurrentClient,
        currtUser,
        clearCurrentUser,
        updateUser,
        getOrders,
        currOrder,
        updateOrder,
        getSkus,
        updateSku,
        addCategories,
        currentCategory,
        updateCategories,
        clearCategory,
        clientCategories,
        clientName,
        clearAdmin,
        removeError,
        maptoWhereHouse,
        setLoading,
        clearMsg,
        setMsg,
        uploadCategoryPhoto,
        uploadClientCSV,
        uploadUserCSV,
        uploadSkuCSV,
        setAdminLogin,
        clearAllClients,
        timeOut,
        clearAllUsers,
        clearAllOrder,
        getClientName,
        clearClientName,
        clearCurrentOrder,
        clearSkus,
        clearAllState,
        syncOrder,
        clearAllPaginationState,
        clientPaginationAndSearchingState, userPaginationAndSearchingState, orderPaginationAndSearchingState, skuPaginationAndSearchingState,
        clients: state.clients,
        admin: state.admin,
        client: state.client,
        currentClient: state.currentClient,
        users: state.users,
        user: state.user,
        currentUser: state.currentUser,
        auth: state.auth,
        orders: state.orders,
        currorder: state.currorder,
        skus: state.skus,
        currCategory: state.currCategory,
        error: state.error,
        warehouse: state.warehouse,
        loading: state.loading,
        clientname: state.clientname,
        productManager: state.productManager,
        msg: state.msg,

        clientPagination: state.clientPagination,
        userPagination: state.userPagination,
        orderPagination: state.orderPagination,
        skuPagination: state.skuPagination
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminState;
