import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import AddClient from "./components/Client/AddClient/AddClient";
import AddUsers from "./components/Users/AddUser/AddUser";
import AdminDashboard from "./components/Dashboard/AdminDashboard";
import Clients from "./components/Client/Clients";
import EditOrder from "./components/Order/EditOrder/EditOrder";
import EditSku from "./components/Sku/EditSku/EditSku";
import Footer from "./components/utilsComponent/Footer";
import Login from "./components/Login/Login";
import Order from "./components/Order/Order";
import Sku from "./components/Sku/Sku";
import Users from "./components/Users/User";
import PrivateRoutes from "./components/utilsComponent/PrivateRoutes";
import PageNotFound from "./components/utilsComponent/PageNotFound";

const App = () => {
  return (
    <div className="App">
      <main className="main">
        <Switch>
          <Route
            exact
            path="/login"
            render={(history) => <Login {...history}> </Login>}
          ></Route>

          <PrivateRoutes
            path="/dashboard"
            exact
            component={AdminDashboard}
          ></PrivateRoutes>

          <PrivateRoutes
            path="/clients"
            exact
            component={Clients}
          ></PrivateRoutes>

          <PrivateRoutes
            path="/clients/addclient"
            exact
            component={AddClient}
          ></PrivateRoutes>

          <PrivateRoutes
            path="/clients/viewclient"
            exact
            component={AddClient}
          ></PrivateRoutes>

          <PrivateRoutes
            path="/users"
            exact
            component={Users}
          ></PrivateRoutes>

          <PrivateRoutes
            path="/users/addusers"
            exact
            component={AddUsers}
          ></PrivateRoutes>

          <PrivateRoutes
            path="/orders"
            exact
            component={Order}
          ></PrivateRoutes>

          <PrivateRoutes
            path="/order/review"
            exact
            component={EditOrder}
          ></PrivateRoutes>

          <PrivateRoutes
            path="/sku"
            exact
            component={Sku}
          ></PrivateRoutes>
          <PrivateRoutes
            path="/sku/review"
            exact
            component={EditSku}
          ></PrivateRoutes>
          <Route
            path="*"
            component={Login}></Route>
          {/* <Route component={PageNotFound}></Route> */}
        </Switch>
      </main>
      <Footer> </Footer>
    </div>
  );
};

export default App;
