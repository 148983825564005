export const GET_CLIENTS = "GET_CLIENTS";
export const ADD_CLIENT = "ADD_CLIENT";
export const CURR_CLIENT = "CURR_CLIENT";
export const WAREHOUSE = "WAREHOUSE";
export const CLIENTNAME = "CLIENTNAME";
export const PRODUCTMANAGER = "PRODUCTMANAGER";

export const GET_USERS = "GET_USERS";
export const ADD_USERS = "ADD_USERS";
export const CURR_USER = "CURR_USER";

export const GET_ORDERS = "GET_ORDERS";
export const UPDATE_ORDERS = "UPDATE_ORDERS";
export const CURR_ORDER = "CURR_ORDER";

export const GET_SKU = "GET_SKU";
export const GET_ALL_SKU = "GET_ALL_SKU";
export const CURR_SKU = "CURR_SKU";

export const ADD_CATEGORY = "ADD_CATEGORY";
export const CURR_CATEGORY = "CURR_CATEGORY";

export const ADMIN = "ADMIN";

export const ERROR = "ERROR";

export const SET_LOADING = "SET_LOADING";

export const SET_LOADINGFALSE = "SET_LOADINGFALSE";
export const MSG = "MSG";
