import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";


import { isEmpty, flatten } from "lodash";
import Navbar from "../utilsComponent/Navbar";
import AdminContext from "../../contextAPI/AdminContext";
import Spinner from "../utilsComponent/Spinner";
import Alerts from "../utilsComponent/ALerts";
import OrderTable from "./OrderTable";
import headers from "../../csvHeader/orderHeader";
import PaginationServerSide from "../utilsComponent/PaginationServerSide";
import Search from "../utilsComponent/Search";


const Order = () => {
  const {
    getOrders,
    orders,
    currOrder,
    clearMsg,
    loading,
    msg,
    clearAllOrder,
    syncOrder,
    orderPagination,
    orderPaginationAndSearchingState
  } = useContext(AdminContext);
  const [isOrderSync, setIsOrderSync] = useState(true);
  let [orderState, setOrderState] = useState({
    from: orderPagination?.from ? orderPagination?.from : "",
    to: orderPagination?.to ? orderPagination?.to : "",
    page: orderPagination?.page ? orderPagination?.page : 1,
    limit: orderPagination?.limit ? (orderPagination?.limit) : 20,
    limitOption: [20, 50, "All"],
    searchField: orderPagination?.searchField ? orderPagination?.searchField : "_",
    searchValue: orderPagination?.searchValue ? orderPagination?.searchValue : "",
    searchingOption: ["Date", "Order", "ClientName", "Status"],
    orders,
    sortBy: "createdAt",
    serachOrderNotBySearchValue: true
  });
  const [orderBy, setOrderBy] = useState("desc");
  const messages = {
    label: {
      from: "From",
      to: "To",
      show: "Show",
      entries: "entries",
      search: "Search",
    },
    button: {
      goback: "Home",
      download: "Download To CSV",
    },
  };

  const changeInputValue = (field, value) => {
    setOrderState({
      ...orderState,
      [field]: value,
    });
    if (field === "searchField" && value === "_") {
      orderState.searchField = "";
      orderState.searchValue = "";
      getSearchValue();
    }
  };

  const setChangeLimit = (e) => {
    setOrderState({
      ...orderState,
      limit: e.target.value,
      page: 1,
    });
  };
  const setSearchingValues = async (value) => {
    orderState = { ...orderState, searchField: value?.searchField, searchValue: value?.searchValue ,page: 1,serachOrderNotBySearchValue:false,
    from:value?.from,to:value?.to}
    setOrderState({
      ...orderState,
      searchField: value?.searchField,
      searchValue: value?.searchValue,
      page: 1,
      serachOrderNotBySearchValue:false,
      from:value?.from,
      to: value?.to,
    })
  }

  const callbackFunctionFromPagination = (page) => {
    setOrderState({
      ...orderState,
      page: parseInt(page),
    });
  };

  const changeOrderBy = () => {
    if (orderBy === "asc") {
      setOrderBy("desc");
    } else {
      setOrderBy("asc");
    }
  };
  useEffect(() => {
    if (orderState.searchField == "_") {
      changeInputValue("searchValue", "");
    }
  }, [orderState.searchField === "_"]);

  useEffect(async () => {
    if(orderState?.serachOrderNotBySearchValue){
    await getSearchValue();
    }
  }, [
    orderState.limit,
    orderState.page  ,
    isOrderSync,
    orderState.sortBy,
    orderBy,
  ]);

  useEffect(async () => {
    const { page, limit, searchField, searchValue,from,to } = orderState
    await orderPaginationAndSearchingState(page, limit, searchField, searchValue,from,to)
  }, [orderState.page, orderState.limit, orderState.searchField, orderState.searchValue,orderState?.from,orderState?.to])


  const getSearchValue = async (value) => {
    let { limit, page, searchValue, searchField, from, to, sortBy } =
      orderState;
    page = value ? 1 : page;

    let searchFieldForBackend = "";
    if (searchField === "Order") {
      searchFieldForBackend = "orderReferenceNo";
    }
    if (searchField === "Status") {
      searchFieldForBackend = "status";
    }
    if (searchField === "ClientName") {
      searchFieldForBackend = "clientName";
    }
    if (searchField === "Date") {
      searchFieldForBackend = "createdAt";
    }

    if (searchField === "Date") {
      let currentDate = new Date();
      var day = 60 * 60 * 24 * 1000;
      if (from !== "" && to === "") {
        to = new Date(from.getTime() + day);
        await getOrders(
          limit,
          page,
          searchFieldForBackend,
          searchValue,
          setFromDate(from),
          setToDate(to),
          sortBy,
          orderBy
        );
      } else if (from === "" && to !== "") {
        from = new Date(to.getTime() - day);
        await getOrders(
          limit,
          page,
          searchFieldForBackend,
          searchValue,
          setFromDate(from),
          setToDate(to),
          sortBy,
          orderBy
        );
      } else if (from !== "" && to !== "") {
        await getOrders(
          limit,
          page,
          searchFieldForBackend,
          searchValue,
          setFromDate(from),
          setToDate(to),
          sortBy,
          orderBy
        );
      }
    } else {
      await getOrders(
        limit,
        page,
        searchFieldForBackend,
        searchValue,
        setFromDate(from),
        setToDate(to),
        sortBy,
        orderBy
      );
    }

    orderState = { ...orderState,serachOrderNotBySearchValue:true }
    setOrderState({
      ...orderState,
      serachOrderNotBySearchValue:true
    })
  };



  const setToDate = (date) => {
    let time = moment(date).toDate();
    time.setHours(23);
    time.setMinutes(59);
    time.setSeconds(59);
    time.setMilliseconds(0);
    return time;
  };
  const setFromDate = (date) => {
    let time = moment(date).toDate();
    time.setHours(0);
    time.setMinutes(0);
    time.setSeconds(1);
    time.setMilliseconds(0);
    return time;
  };
  let csvData = orders?.orders?.map((order) =>
    order?.linesItems?.map((lineitem) => {
      return {
        clientName: order.clientName,
        createdAt: order.createdAt,
        orderStatus: order.status,
        orderReferenceNo: order.orderReferenceNo,
        orderPlacer: order?.orderPlacer,
        userDelivaryDetail: order?.userDelivaryDetail,
        userPersonalDetail: order?.userPersonalDetail,
        ...lineitem,
      };
    })
  );
  const csvReport = {
    data: csvData && csvData.length ? flatten(csvData) : [],
    headers: headers,
    filename: "Order.csv",
  };
  const goBackToHome = async () => {
    await orderPaginationAndSearchingState("", orderPagination?.limit, "", "","","")
    clearMsg();
    clearAllOrder();
  }

  console.log("orderState",orderPagination);

  return (
    <div>
      <Navbar></Navbar>
      <div className="container-fluid mb-4 px-5">
        <h1 className="display-4">Orders</h1>
        <Link
          to="/dashboard"
          className="btn button"
          onClick={async () => {
            await goBackToHome()
          }}
        >
          {messages.button.goback}
        </Link>
        {msg && <Alerts msg={msg}></Alerts>}
        <div className="row mt-2">
          <div className="col-md-4 mb-2">
            {/* <button className="btn button btn-block"> */}
            <CSVLink
              {...csvReport}
              // style={{ color: "#fff", textDecoration: "none" }}
              className="btn button btn-block"
            >
              <span className="csvLink">{messages.button.download}</span>
            </CSVLink>
            {/* </button> */}
          </div>
        </div>
        <div className="row">
          <Search
            getSearchValueFromTable={getSearchValue}
            serachOption={orderState?.searchingOption}
            searchField={orderState?.searchField}
            searchValue={orderState?.searchValue}
            from={orderState?.from}
            to={orderState?.to}
            setSearchingValues={setSearchingValues}
          />
        </div>

        <div className="show-limit d-flex justify-content-end nmt-10">
          <p className="pt-3" style={{ fontWeight: "bold" }}>
            {messages.label.show}
          </p>
          <select
            name="range"
            className="form-control mx-1 selectList"
            style={{ width: "70px" }}
            onChange={setChangeLimit}
            value={orderState?.limit}
          >
            {orderState.limitOption.map((limitValue) => (
              <option key={limitValue} value={limitValue}>
                {limitValue}
              </option>
            ))}
          </select>
          <p className="pt-3" style={{ fontWeight: "bold" }}>
            {messages.label.entries}
          </p>
        </div>

        {loading && isEmpty(orders) ? (
          <Spinner />
        ) : (
          <div>
            <>
              {loading && <Spinner />}
              <div className="orderTable">
                <OrderTable
                  orders={orders?.orders}
                  currOrder={currOrder || {}}
                  syncOrder={syncOrder}
                  isOrderSync={isOrderSync}
                  setIsOrderSync={setIsOrderSync}
                  changeInputValue={changeInputValue}
                  changeOrderBy={changeOrderBy}
                />
              </div>
            </>
            <PaginationServerSide
              callBack={callbackFunctionFromPagination}
              total={orders.totalPages}
              initialPage={orderState?.page - 1}
            />
          </div>
        )}

      </div>
    </div>
  );
};

export default Order;


