import React, { useState } from "react";
import Navbar from "../../utilsComponent/Navbar";
import { useHistory,useLocation } from "react-router-dom";
import { useContext } from "react";
import AdminContext from "../../../contextAPI/AdminContext";
import { useEffect } from "react";
import { size } from "lodash";
import EditOrderTable from "./EditOrderTable";
import EditOrderCard from "./EditOrderCard";
import ALerts from "../../utilsComponent/ALerts";
import { find, isEmpty } from "lodash";

const EditOrder = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    currorder,
    currOrder,
    updateOrder,
    setCurrentUser,
    clearMsg,
    clearCurrentOrder,
    msg,
    loading,
  } = useContext(AdminContext);

  const [currentOrder, setCurrentOrder] = useState(null);
  const [isDraft, setIsDraft] = useState(false);

  const messages = {
    heading: {
      orderReview: "Order Review",
    },
  };

  useEffect(() => {
    setCurrentUser();
    const temporaryCurrentOrder = JSON.parse(localStorage.getItem("currOrder"));
    currOrder(temporaryCurrentOrder);
    setCurrentOrder(temporaryCurrentOrder);
  }, []);

  const onBack = () => {
    clearMsg();
    history.push({ pathname:"/orders"});
    clearCurrentOrder();
    localStorage.removeItem("currOrder");
  };

  const onSubmit = (order) => {
    let checkOrderLineItemsHasNoDarft = isEmpty(
      find(order.linesItems, ["status", "requested"])
    )
      ? false
      : true;
    if (checkOrderLineItemsHasNoDarft) {
      setIsDraft(true);
      setTimeout(() => {
        clearMsg();
        setIsDraft(false);
      }, 4000);
    } else {
      let linesItems = order.linesItems;
      if (size(linesItems) > 0) {
        updateOrder(linesItems, order._id, history,location);
      } else {
        history.push({ pathname: "/orders" });
      }
    }
  };

  const changeStatus = (id, newStatus) => {
    let orderToUpdate = currentOrder;
    let itemIndex = orderToUpdate.linesItems.findIndex((res) => res.id === id);
    orderToUpdate.linesItems[itemIndex].status = newStatus;
    setCurrentOrder({
      ...currentOrder,
      linesItems: orderToUpdate.linesItems,
    });
  };

  const changeAllLineItemsStatus = (value) => {
    let orderToUpdate = currentOrder;
    for (let i in orderToUpdate.linesItems) {
      orderToUpdate.linesItems[i].status = value;
    }
    setCurrentOrder({
      ...currentOrder,
      linesItems: orderToUpdate.linesItems,
    });
  };

  return (
    <div>
      <Navbar></Navbar>
      <div className="container-fluid mb-4 px-5">
        <h1 className="display-3">{messages.heading.orderReview}</h1>
        <input
          type="button"
          className="btn btn-primary mb-3"
          value="Go Back"
          onClick={onBack}
        />
        {msg && <ALerts msg={msg}></ALerts>}
        {isDraft && (
          <ALerts
            msg="Please approve or cancel all Items"
            type="error"
          ></ALerts>
        )}

        <EditOrderCard currorder={currentOrder || currorder} />
        <div>
          <EditOrderTable
            currorder={currentOrder || currorder}
            loading={loading}
            changeStatus={changeStatus}
            changeAllLineItemsStatus={changeAllLineItemsStatus}
            updateAndSubmit={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default EditOrder;
