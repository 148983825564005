import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom"
import { CSVLink } from "react-csv";
import { isEmpty } from "lodash";


import Navbar from "../utilsComponent/Navbar";
import AdminContext from "../../contextAPI/AdminContext";
import Spinner from "../utilsComponent/Spinner";
import Alerts from "../utilsComponent/ALerts";
import clientHeader from "../../csvHeader/clientHeader";
import ClientTable from "./ClientTable";
import ClientUploadForm from "./ClientUploadForm";
import PaginationServerSide from "../utilsComponent/PaginationServerSide";

const Clients = () => {
  const history = useHistory();
  const {
    getAllclients,
    clients,
    currClient,
    loading,
    msg,
    clearMsg,
    clearAllClients,
    clientPagination,
    clientPaginationAndSearchingState
  } = useContext(AdminContext);

  const [clientState, setClientState] = useState({
    limit: clientPagination?.limit ? (clientPagination?.limit) : 20,
    page:clientPagination?.page ? clientPagination?.page : 1,
    limitOption: [20, 50, "All"],
  });

  

  const messages = {
    button: {
      goback: "Home",
      addClient: "Add New Client",
      download: "Download To CSV",
    },
    label: {
      show: "Show",
      entries: "entries",
    },
  };
  const setChangeLimit = (e) => {
    setClientState({
      ...clientState,
      limit: e.target.value,
      page: 1,
    });
  };

  const callbackFunctionFromPagination = (page) => {
    setClientState({
      ...clientState,
      page: parseInt(page),
    });
  };

  useEffect(() => {
    const { limit, page } = clientState;
    getAllclients(limit, page);
  }, [clientState.limit, clientState.page]);

  const csvReport = {
    data: clients.clients || [],
    headers: clientHeader,
    filename: "Client.csv",
  };
  const currClientFromTable = async (clientData)=>{
    await currClient(clientData);
  }
  useEffect(async()=>{
    const {page, limit} = clientState;
    await clientPaginationAndSearchingState(page, limit)
  },[clientState.page, clientState.limit])

  return (
    <div>
      <Navbar></Navbar>
      <div className="container-fluid px-5 mb-4">
        <h1 className="display-4">Clients</h1>
        <Link
          to="/dashboard"
          className="btn button"
          onClick={() => {
            clearMsg();
            clearAllClients();
          }}
        >
          {messages.button.goback}
        </Link>
        {msg && <Alerts msg={msg}></Alerts>}
        <div className="row mt-2">
          <div className="col-md-4 mb-2">
            <button
              className="btn button btn-block"
              onClick={(e) => {
                localStorage.removeItem("currClient");
                history.push({
                  pathname: "/clients/addclient",
                  state: { view: false, update: false, },
                });
              }}
            >
              {messages.button.addClient}
            </button>
          </div>
          <div className="col-md-4 mb-2 ">
              <CSVLink
                {...csvReport}
                className="btn button btn-block"
              >
                <span className="csvLink">{messages.button.download}</span>
              </CSVLink>
            {/* </button> */}
          </div>
          <ClientUploadForm />
        </div>

        <div className="table-show-limit">
          <div></div>
          <div className="show-limit">
            <p className="pt-3" style={{ fontWeight: "bold" }}>
              {messages.label.show}
            </p>
            <select
              name="range"
              className="form-control mx-1 selectList"
              style={{ width: "70px" }}
              onChange={setChangeLimit}
              value={clientState.limit}
            >
              {clientState.limitOption.map((limitValue) => (
                <option key={limitValue} value={limitValue}>
                  {limitValue}
                </option>
              ))}
            </select>
            <p className="pt-3" style={{ fontWeight: "bold" }}>
              {messages.label.entries}
            </p>
          </div>
        </div>

        {loading && isEmpty(clients) ? (
          <Spinner />
        ) : (
          <div>
            <>
              {loading && <Spinner />}
              <div className="clientTable">
                <ClientTable
                  clients={clients.clients || []}
                  currClient={currClientFromTable}
                />
              </div>
            </>
            <PaginationServerSide
              callBack={callbackFunctionFromPagination}
              total={clients.totalPages}
              initialPage={clientState?.page-1}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Clients;
