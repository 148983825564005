import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import AdminContext from "../../contextAPI/AdminContext";
import setAuthTokenAdmin from "../../contextAPI/setAuthTokenAdmin";
import LogoutModel from "./LogoutModel";

const Navbar = () => {
  const {
    admin,
    setMsg,
    removeError,
    clearAdmin,
    timeOut,
    clearAllState,
  } = useContext(AdminContext);
  const [confirmLogout, setConfirmLogout] = useState(false);
  const history = useHistory();
  const messages = {
    button: {
      mainBtn: "Admin",
      logout: "Logout",
    },
  };

  const adminLogout = (e) => {
    localStorage.clear();
    removeError();
    setAuthTokenAdmin(false);
    setMsg("you are logged out");
    timeOut();
    clearAdmin();
    history.push({
      pathname: "/login",
    });
  };
  const confirmLogoutonClick = () => {
    setConfirmLogout(true);
  };

  return (
    <>
      <nav
        className=" px-5 navbar navbar-expand-sm bg-light navbar-light nmb-10"
      >
        <Link
          to="/dashboard"
          className="navbar-brand"
          onClick={() => clearAllState()}
          // style={{
          //   fontWeight: "bold",
          //   color: "black",
          // }}
        >
          {messages.button.mainBtn}
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse adminNavbar" id="navbar">
          <ul className="navbar-nav ml-auto">
            {admin && (
              <li className="nav-item active text-center">
                {/* <Link className="nav-link" to="/login" onClick={adminLogout}
              data-toggle="modal"
              data-target="#exampleModalCenter"
              >
                {messages.button.logout}
              </Link> */}

                <button
                  type="button"
                  className="nav-link logoutSureButton"
                  data-toggle="modal"
                  data-target="#logoutModel"
                  onClick={confirmLogoutonClick}
                >
                  {messages.button.logout}
                </button>
              </li>
            )}
          </ul>
        </div>
      </nav>
      {confirmLogout && <LogoutModel adminLogout={adminLogout} />}
    </>
  );
};

export default Navbar;
