import React from "react";
import footerImage from "../../assets/images/footerImage2.png";

const Footer = () => {
  const messages = {
    footerText: process.env.REACT_APP_FOOTER_TEXT,
  };

  return (
    <footer className="adminFooter">
      <div>
        <img
          className="footerImage"
          src={footerImage}
        />
        {messages?.footerText?.split(" ")[0]} <b>{messages?.footerText?.split(" ")[1]}</b> | copyright {new Date().getFullYear()}
      </div>
      
    </footer>
  );
};

export default Footer;
