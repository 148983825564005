import React from "react";
import classnames from "classnames";

const Input = ({ type, name, error, callBack }) => {
  const onChange = (e) => {
    callBack(e.target.value);
  };

  return (
    <>
      <input
        type={type}
        name={name}
        className={classnames("form-control", {
          "is-invalid": error,
        })}
        onChange={onChange}
      />
      <small>{error}</small>
    </>
  );
};

export default Input;
