const headers = [
  { label: "name", key: "name" },
  { label: "status", key: "status" },
  { label: "extension", key: "extension" },
  { label: "warehouse", key: "warehouse" },
  { label: "orderApprovel", key: "orderApprovel" },
  { label: "payment", key: "payment" },
  { label: "guestAccess", key: "guestAccess" },
  { label: "nopricing", key: "nopricing" },
  { label: "pricingformfeed", key: "pricingformfeed" },
  { label: "maintainPricing", key: "maintainPricing" },
  { label: "isCategoryEnabled", key: "isCategoryEnabled" },
  { label: "isDeliveryEnabled", key: "isDeliveryEnabled" },
  { label: "delivery", key: "delivery" },
  { label: "photo", key: "photo" },
  { label: "text", key: "text" },
];

export default headers;
