const headers = [
  { label: "name", key: "name" },
  { label: "clientName", key: "clientName" },
  { label: "login", key: "login" },
  { label: "email", key: "email" },
  { label: "password", key: "password" },
  { label: "role", key: "role" },
  { label: "organization", key: "organization" },
  { label: "phone", key: "phone" },
  { label: "address1", key: "address1" },
  { label: "address2", key: "address2" },
  { label: "town", key: "town" },
  { label: "state", key: "state" },
  { label: "postcode", key: "postcode" },
  { label: "userstatus", key: "userstatus" },
  { label: "delivery.name", key: "delivery.name" },
  { label: "delivery.organization", key: "delivery.organization" },
  { label: "delivery.town", key: "delivery.town" },
  { label: "delivery.state", key: "delivery.state" },
  { label: "delivery.postcode", key: "delivery.postcode" },
  { label: "delivery.address1", key: "delivery.address1" },
  { label: "delivery.address2", key: "delivery.address2" },
  { label: "delivery.phone", key: "delivery.phone" },
  { label: "delivery.email", key: "delivery.email" },
];

export default headers;
