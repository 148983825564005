import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import AdminContext from "../../contextAPI/AdminContext";

const SkuTable = ({ sku, onChange, changeOrderBy,
 }) => {
  const history = useHistory();
  const { clientName } = useContext(AdminContext);

  const goToEdit = (sku) => {
    history.push({
      pathname:"/sku/review",
    })
    localStorage.setItem("sku", JSON.stringify(sku));
    clientName(sku.id, sku.clientname);
  };

  const messages = {
    button: {
      edit: "Edit",
    },
  };

  return (
    <div style={{ overflowX: "auto", width: "100%" }}>
      <table
        className="table table-stripped table-sm scroll-table"
        cellSpacing="0"
        width="100%"
        id="sortTable"
      >
        <thead className="thead-dark">
          <tr>
            <th>
              <button
                type="button"
                onClick={(e) => {
                  onChange("sortBy", "itemcode");
                  changeOrderBy();
                }}
                className="sort-table-btn"
              >
                SKU
              </button>
            </th>
            <th>
              <button
                type="button"
                onClick={(e) => {
                  onChange("sortBy", "ITEMDESCRIPTION");
                  changeOrderBy();
                }}
                className="sort-table-btn"
              >
                Name
              </button>
            </th>
            <th>
              <button
                type="button"
                onClick={(e) => {
                  onChange("sortBy", "ITEMSTATUS");
                  changeOrderBy();
                }}
                className="sort-table-btn"
              >
                Status
              </button>
            </th>
            {/* <th>
              <button
                type="button"
                onClick={(e) => {
                  sortTable(3);
                }}
                className="sort-table-btn"
              >
                Client
              </button>
            </th> */}
            <th></th>
          </tr>
        </thead>

        <tbody id="table2">
          {sku &&
            sku.map((sku) => (
              <tr key={sku.id}>
                <td
                  className="lead-sm"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={sku.id}
                >
                  {sku.id}
                </td>
                <td>{sku.description}</td>
                <td>{sku.status}</td>
                {/* <td>{sku.clientname}</td> */}
                <td>
                  <input
                    type="button"
                    value={messages.button.edit}
                    className="btn btn-info"
                    onClick={(e) => goToEdit(sku)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default SkuTable;
