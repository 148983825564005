import React, { useContext, useEffect } from "react";
import Navbar from "../../utilsComponent/Navbar";
import AdminContext from "../../../contextAPI/AdminContext";
import AddClientFormContainer from "./AddClientFormContainer";
import Spinner from "../../utilsComponent/Spinner";

const AddClient = () => {
  const { currentClient, currClient, loading } = useContext(AdminContext);
  const localCurrentClient = localStorage.getItem("currClient") && JSON.parse(localStorage.getItem("currClient"));

  const messages = {
    label: {
      client: "Client",
    },
  };

  useEffect(async() => {
    if (!currentClient?.id) {
      if (localCurrentClient) {
        await currClient(localCurrentClient);
      }
    }
  }, []);

  return (
    <div>
      <Navbar />
      <div className="container-fluid mb-4 px-5">
        <h1 className="display-4 ">
          {messages.label.client} {localCurrentClient && localCurrentClient?.name}
        </h1>
        <AddClientFormContainer />
      </div>
    </div>
  );
};

export default AddClient;
