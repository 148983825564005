import React from "react";
import moment from "moment";

const EditOrderCard = ({ currorder }) => {
  const messages = {
    label: {
      orderRefNo: "Order Reference No",
      date: "Date",
      placedBy: "Order Placed By",
      deliveryTo: "For Delivery To",
      orderTaker: "Organisation of order taker",
      deliveryAddress: "Organisation of delivery address",
    },
  };
  return (
    <div className=" card p-4  border-dark mb-5">
      <div className="">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="" className="col-md-4 col-form-label">
                    {messages.label.orderRefNo}
                  </label>
                  <div className="col-md-8 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      value={currorder?.orderReferenceNo}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="" className="col-md-4 col-form-label">
                    {messages.label.date}
                  </label>
                  <div className="col-md-8 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={moment(currorder?.createAt).format("DD-MM-YYYY")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="" className="col-md-4 col-form-label">
                    {messages.label.placedBy}
                  </label>
                  <div className="col-md-8 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      value={currorder?.orderPlacer?.name}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="" className="col-md-4 col-form-label">
                    {messages.label.orderTaker}
                  </label>
                  <div className="col-md-8 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={currorder?.orderPlacer?.organization}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="" className="col-md-4 col-form-label">
                    {messages.label.deliveryTo}
                  </label>
                  <div className="col-md-8 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      value={currorder?.userDelivaryDetail?.name}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="" className="col-md-4 col-form-label">
                    {messages.label.deliveryAddress}
                  </label>
                  <div className="col-md-8 px-0 px-md-3 px-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={currorder?.userDelivaryDetail?.organization}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOrderCard;
