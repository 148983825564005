import React from "react";

const AddClientPricingOption = ({
  changePricingOption,
  labelpricingFromfeed,
  labelmaintainPricing,
  labelnoPricing,
  pricingFeedCheck,
  maintainPricingCheck,
  noPricingCheck,
  pricingOptionDisable,
}) => {
  return (
    <div className="row">
      <div className="col-md-4">
        <div className="form-group row">
          <label
            htmlFor="exampleRadios1"
            className="col-7 col-form-label label"
          >
            {labelpricingFromfeed}
          </label>
          <div className="col-5">
            <input
              name="price"
              type="radio"
              value="option1"
              id="exampleRadios1"
              className="hanan"
              checked={pricingFeedCheck}
              onChange={(e) => changePricingOption(e, "pricingFeed")}
              disabled={pricingOptionDisable}
              style={{
                marginTop: "10px",
              }}
            />
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group row">
          <label
            htmlFor="exampleRadios2"
            className="col-7 col-form-label label"
          >
            {labelmaintainPricing}
          </label>
          <div className="col-5">
            <input
              name="price"
              type="radio"
              value="option2"
              id="exampleRadios2"
              className="hanan"
              checked={maintainPricingCheck}
              onChange={(e) => changePricingOption(e, "maintainPrice")}
              disabled={pricingOptionDisable}
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group row">
          <label
            htmlFor="exampleRadios3"
            className="col-7 col-form-label label"
          >
            {labelnoPricing}
          </label>
          <div className="col-5">
            <input
              name="price"
              type="radio"
              value="option3"
              id="exampleRadios3"
              className="hanan"
              checked={noPricingCheck}
              onChange={(e) => changePricingOption(e, "nopricing")}
              disabled={pricingOptionDisable}
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClientPricingOption;
