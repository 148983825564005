import React from "react";
import { useHistory } from "react-router-dom";

const ClientTable = ({ clients, currClient }) => {
  const history = useHistory();

  const messages = {
    heading: {
      client: "Client",
      status: "Status",
    },
    button: {
      edit: "Edit",
      view: "View",
    },
  };

  const editClient = (client) => {
    history.push({
      pathname: "/clients/addclient",
      state: { view: false, update: true},
    });
    currClient(client);
  };

  const viewClient = (client) => {
    history.push({
      pathname: "/clients/addclient",
      state: { view: true, update: false },
    });
    currClient(client);
  };

  return (
    <div style={{ overflowX: "auto", width: "100%" }}>
      <table className="table table-sm ">
        <thead className="thead-dark">
          <tr>
            <th>{messages.heading.client}</th>
            <th>{messages.heading.status}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => (
            <tr key={client._id}>
              <td>{client.name}</td>
              <td> {client.status.toLowerCase()} </td>
              <td>
                <button
                  className="btn button table-btn "
                  onClick={() => {
                    editClient(client);
                  }}
                >
                  {messages.button.edit}
                </button>
                <button
                  className="btn button ml-2"
                  onClick={() => viewClient(client)}
                >
                  {messages.button.view}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClientTable;
