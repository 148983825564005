import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import { find } from "lodash";

const OrderTable = ({
  orders,
  currOrder,
  syncOrder,
  setIsOrderSync,
  isOrderSync,
  changeInputValue,
  changeOrderBy,
}) => {
  const history = useHistory();
  const [ordersData, setOrdersData] = useState({
    currentPage: 1,
    currentPosts: [],
    order: "asc",
  });
  const messages = {
    button: {
      orderNumber: "Order Number",
      dateCreated: "Date Created",
      status: "Status",
      client: "Client",
      edit: "Edit",
    },
  };

  const updateOrdersData = (field, value) => {
    setOrdersData({
      ...ordersData,
      [field]: value,
    });
  };

  useEffect(() => {
    updateOrdersData("currentPosts", orders);
  }, [orders]);

  const goToCurrentOrderPage = (order)=>{
    history.push({
      pathname: "/order/review",
    });
    currOrder(order)
  }

  return (
    <div style={{ overflowX: "auto" }}>
      <table
        className="table table-stripped table-sm  scroll-table"
        id="sortTable"
      >
        <thead className="thead-dark">
          <tr>
            <th>
              <button
                type="button"
                onClick={(e) => {
                  changeInputValue("sortBy", "orderReferenceNo");
                  changeOrderBy();
                }}
                className="sort-table-btn"
              >
                {messages.button.orderNumber}
              </button>
            </th>
            <th>
              <button
                type="button"
                onClick={(e) => {
                  changeInputValue("sortBy", "createdAt");
                  changeOrderBy();
                }}
                className="sort-table-btn"
              >
                {messages.button.dateCreated}
              </button>
            </th>
            <th>
              <button
                type="button"
                onClick={(e) => {
                  changeInputValue("sortBy", "status");
                  changeOrderBy();
                }}
                className="sort-table-btn"
              >
                {messages.button.status}
              </button>
            </th>
            <th>
              <button
                type="button"
                onClick={(e) => {
                  changeInputValue("sortBy", "clientName");
                  changeOrderBy();
                }}
                className="sort-table-btn"
              >
                {messages.button.client}
              </button>
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody className="fbody">
          {ordersData && ordersData?.currentPosts?.map((order) => (
            <tr key={order._id}>
              <td
                className="lead-sm"
                data-toggle="tooltip"
                data-placement="bottom"
                title={order._id}
              >
                {order?.orderReferenceNo}
              </td>
              <td>
                <Moment format="DD/MM/YYYY">{order.createdAt}</Moment>
                {/* <Moment format="DD/MM/YYYY">{order.createdAt.split("T")[0]}</Moment> */}
              </td>
              <td>
                {find(order.linesItems, { status: "requested" })
                  ? "Pending"
                  : "Submitted"}
              </td>

              <td>{order.clientName}</td>
              <td>
                {/* <Link
                  to="/order/review"
                  className="btn btn-info"
                  onClick={() => currOrder(order)}
                >
                  {messages.button.edit}
                </Link> */}
                <button  className="btn btn-info" onClick={()=>goToCurrentOrderPage(order)}>
                {messages.button.edit}
                </button>
                {order.status == "Submitted" &&
                order.ostendoOrderId == "" &&
                !order?.linesItems.every(
                  (res) => res.status === "cancelled"
                ) ? (
                  <button
                    onClick={async () => {
                      await syncOrder(order._id);
                      setIsOrderSync(!isOrderSync);
                    }}
                    className="btn btn-danger ml-2"
                  >
                    Sync
                  </button>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderTable;
