const headers = [
  { label: "client", key: "clientName" },
  { label: "Order Reference Number", key: "orderReferenceNo" },
  { label: "Order status", key: "orderStatus" },
  { label: "createdAt", key: "createdAt" },
  { label: "Sku", key: "code" },
  { label: "name", key: "name" },
  { label: "quantity", key: "quantity" },
  { label: "unit", key: "unit" },
  { label: "comment", key: "comment" },
  { label: "Review", key: "productManager" },
  { label: "status", key: "status" },
  { label: "orderPlacer Name", key: "orderPlacer.name" },
  { label: "userDelivaryDetail Name", key: "userDelivaryDetail.name" },
  { label: "userDelivaryDetail Email", key: "userDelivaryDetail.email" },
  { label: "userDelivaryDetail Address1", key: "userDelivaryDetail.address1" },
  { label: "userDelivaryDetail Address2", key: "userDelivaryDetail.address2" },
  {
    label: "userDelivaryDetail Organization",
    key: "userDelivaryDetail.organization",
  },
  {
    label: "userDelivaryDetail PhoneNumber",
    key: "userDelivaryDetail.phoneNumber",
  },
  { label: "userDelivaryDetail State", key: "userDelivaryDetail.state" },
  { label: "userDelivaryDetail Town", key: "userDelivaryDetail.town" },
  { label: "userDelivaryDetail PostCode", key: "userDelivaryDetail.postcode" },
  { label: "userPersonalDetail Email", key: "userPersonalDetail.email" },
  { label: "userPersonalDetail Name", key: "userPersonalDetail.name" },
  {
    label: "userPersonalDetail Organization",
    key: "userPersonalDetail.organization",
  },
  {
    label: "userPersonalDetail PhoneNumber",
    key: "userPersonalDetail.phoneNumber",
  },
];

export default headers;
