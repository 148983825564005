import React, { useContext, useEffect, useState } from "react";
import { find, isEmpty } from "lodash";
import classnames from "classnames";
import { useLocation, useHistory } from "react-router-dom";
import AdminContext from "../../../contextAPI/AdminContext";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import spinner from "../../../assets/spinner.gif";

import {
  HtmlEditor,
  Inject,
  Link,
  Table,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import AddClientDelivery from "./AddClientDelivery";
import AddClientPricingOption from "./AddClientPricingOption";
import AddClientCategory from "./AddClientCategory";

const AddClientForm = () => {
  const location = useLocation();
  const history = useHistory();

  const messages = {
    label: {
      clientName: "Client Name",
      clientStatus: "Client Status",
      mapToWarehouse: "Map to Warehouse",
      imageForLanding: "Image File for Landing Page",
      logoForLanding: "Logo File for Landing Page",
      extensionForLanding: "Extension for Landing Page",
      textForLandingPage: "Text Block for Landing Page",
      enableOrderApproval: "Enable Order Approval",
      enablePayments: "Enable Payments",
      enableGuestAccess: "Enable Guest Access",
      pricingFromfeed: "Pricing from Feed",
      maintainPricing: "Maintain Pricing",
      noPricing: "No Pricing",
      deliveryCharges: "Delivery Charges",
      enableCategoryDisplay: "Enable Category Display",
      categories: "Categories:",
    },
    button: {
      save: "Save",
      exitWotoutSave: "Exit Without Save",
      exit: "Exit",
    },
  };

  let {
    addClient,
    currentClient,
    updateClient,
    clearCurrentClient,
    currCategory,
    clearCategory,
    error,
    removeError,
    maptoWhereHouse,
    warehouse,
    clientname,
    clearMsg,
    loading,
  } = useContext(AdminContext);
  currCategory = localStorage.getItem("currCategory") ? JSON.parse((localStorage.getItem("currCategory"))) : []


  const toolbarSettings = {
    items: [
      "Bold",
      "CreateTable",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "Undo",
      "Redo",
    ],
  };
  let [clientDefinitionState, setClientDefinitionState] = useState({
    showcategory: false,
    showdelivery: false,
    clientName: "",
    clientStatus: "active",
    wareHouse: "",
    logoImageFile: "",
    logoImageFileLabel: "Choose file",
    landingImageFile: "",
    landingImageFileLabel: "Choose file",
    extension: "",
    text: "",
    orderapprovel: false,
    payment: false,
    guestaccess: false,
    pricingFeed: false,
    maintainPrice: false,
    nopricing: true,
    deliverycharges: 0,
    categoryName: "",
    selectedcategory: "",
    selectedcategoryAllDeleted: false,
    categoryImage: "",
    categoryImageLabel: "Choose file",
    isCategoryEnabled: false,
    isDeliveryEnabled: false,
    categoryArray: [],
    tempCategoryArray: [],
    currentCategories: [],
    categoryNameError: "",
    categoryImageError: "",
    categoryError: "",
    categoriesImages: [],
  });

  const setCurrentClientData = () => {
    const clientState = { ...currentClient };
   
      setClientDefinitionState({
        ...clientDefinitionState,
        clientName: clientState.name,
        text: clientState.text,
        clientStatus: clientState.status,
        extension: clientState.extension,
        orderapprovel: clientState.orderApprovel,
        deliverycharges: clientState.delivery,
        wareHouse: clientState.warehouse,
        showcategory: clientState.isCategoryEnabled,
        isCategoryEnabled: clientState.isCategoryEnabled,
        isDeliveryEnabled: clientState.isDeliveryEnabled,
        showdelivery: clientState.isDeliveryEnabled,
        logoImageFile: clientState.photo,
        logoImageFileLabel: clientState.photo,
        landingImageFile: clientState?.landingPhoto,
        landingImageFileLabel: clientState?.landingPhoto,
        payment: clientState.payment,
        guestaccess: clientState.guestAccess,
        nopricing: clientState.nopricing,
        pricingFeed: clientState.pricingformfeed,
        maintainPrice: clientState.maintainPricing,
      });
      
      
    
  }


  useEffect(async () => {
    if (currentClient?.id) {
      setCurrentClientData();
    }

  }, [currentClient])

  const updateClientDefinitionState = (field, value) => {
    setClientDefinitionState({
      ...clientDefinitionState,
      [field]: value,
    });
  };

  const changeCategoryIsShown = (e) => {
    let categoryShown = e.target.checked;
    setClientDefinitionState({
      ...clientDefinitionState,
      showcategory: categoryShown,
      isCategoryEnabled: categoryShown,
    });
  };

  const changeIsDelived = (e) => {
    let delivered = e.target.checked;
    setClientDefinitionState({
      ...clientDefinitionState,
      showdelivery: delivered,
      isDeliveryEnabled: delivered,
    });
  };

  const onFileChange = (field, fileLabel, value) => {
    if (value) {
      setClientDefinitionState({
        ...clientDefinitionState,
        [field]: value,
        [fileLabel]: value.name,
      });
    }
  };

  const onCategoryFileChange = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      let imageReg = /[\/.](gif|jpg|jpeg|tiff|png)$/i;
      if (imageReg.test(imageFile.name)) {
        const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
        const ist = new File(
          [imageFile],
          `${imageFile.name.replace(".", "_" + currentDate + ".")}`,
          {
            type: imageFile.type,
          }
        );
        const imageShortName = imageFile.name.slice(0, 30);
        let newImageArray = clientDefinitionState.categoriesImages;
        newImageArray.push(ist);
        setClientDefinitionState({
          ...clientDefinitionState,
          categoriesImages: newImageArray,
          categoryImage: ist,
          categoryImageLabel: imageShortName,
          categoryImageError: "",
        });
      } else {
        setClientDefinitionState({
          ...clientDefinitionState,
          categoryImageError: "error in file",
          categoryImage: "",
        });
      }
    } else {
      setClientDefinitionState({
        ...clientDefinitionState,
        categoryImageLabel: "",
        categoryImage: "",
      });
    }
  };

  const changeCategory = (a) => {
    setClientDefinitionState({
      ...clientDefinitionState,
      currentCategories: a,
      selectedcategory: a,
      selectedcategoryAllDeleted: !a.length,
    });
  };
  const onAddCategory = (e) => {
    const categoryRequiredError = "category name field is required";
    setClientDefinitionState({
      ...clientDefinitionState,
      categoryNameError: clientDefinitionState.categoryName
        ? ""
        : categoryRequiredError,
    });
    const catigories = clientDefinitionState.categoryArray;
    const currentCatigories = currCategory;
    if (clientDefinitionState.categoryName) {
      if (clientDefinitionState.categoryImage) {
        const found = find(catigories, {
          name: clientDefinitionState.categoryName,
        });
        const foundInCurrentCategries = currCategory.length !== 0 ? find(currentCatigories, {
          name: clientDefinitionState.categoryName,
        }) : false;

        if (!found && !foundInCurrentCategries) {
          catigories.push({
            name: clientDefinitionState.categoryName,
            image: clientDefinitionState.categoryImage.name
              ? clientDefinitionState.categoryImage.name
              : "no-image.png",
          });

          setClientDefinitionState({
            ...clientDefinitionState,
            categoryName: "",
            categoryImage: "",
            categoryImageLabel: "",
            tempCategoryArray: catigories,
            categoryError: "",
            categoryNameError: "",
            categoryImageError: "",
          });
        } else {
          const errorMessage = `category ${clientDefinitionState.categoryName} already exits.`;
          updateClientDefinitionState("categoryError", errorMessage);
        }
      } else {
        const found = find(catigories, {
          name: clientDefinitionState.categoryName,
        });
        const foundInCurrentCategries = currCategory.length !== 0 ? find(currentCatigories, {
          name: clientDefinitionState.categoryName,
        }) : false;
        if (!found && !foundInCurrentCategries) {
          catigories.push({
            name: clientDefinitionState.categoryName,
            image: "no-image.png",
          });
          setClientDefinitionState({
            ...clientDefinitionState,
            categoryName: "",
            categoryImage: "",
            categoryImageLabel: "",
            tempCategoryArray: catigories,
            categoryNameError: "",
            categoryImageError: "",

            categoryError: "",
          });
        } else {
          const errorMessage = `category ${clientDefinitionState.categoryName} already exits.`;
          setClientDefinitionState({
            ...clientDefinitionState,
            tempCategoryArray: catigories,
            categoryError: errorMessage,
          });
        }
      }
    }
  };

  const changePricingFeed = (e, option) => {
    let isChecked = e.target.checked;
    if (option === "pricingFeed") {
      setClientDefinitionState({
        ...clientDefinitionState,
        pricingFeed: isChecked,
        nopricing: false,
        maintainPrice: false,
      });
    } else if (option === "nopricing") {
      setClientDefinitionState({
        ...clientDefinitionState,
        pricingFeed: false,
        nopricing: isChecked,
        maintainPrice: false,
      });
    } else if (option === "maintainPrice") {
      setClientDefinitionState({
        ...clientDefinitionState,
        pricingFeed: false,
        nopricing: false,
        maintainPrice: isChecked,
      });
    }
  };

  const onExit = (e) => {
    clearCurrentClient();
    clearCategory();
    removeError();
    clearMsg();
    localStorage.removeItem("currClient");
    localStorage.removeItem("currCategory");
    history.push({
      pathname: "/clients",
      state: { reload: true, },
    });
  };
  


  useEffect(() => {
    maptoWhereHouse();
  }, []);

  const checkClientNameExitsOnOstendo = () => {
    let index;
    index =
      clientname.length &&
      !isEmpty(clientDefinitionState.clientName) &&
      clientname.findIndex(
        (x) =>
          x.toLocaleLowerCase() ==
          clientDefinitionState.clientName.toLocaleLowerCase()
      );
    if (index === -1) {
      return true;
    } else {
      return false;
    }
  };

  const checkClientWarehouseExitsOnOstendo = () => {
    let index;
    index =
      warehouse.length &&
      !isEmpty(clientDefinitionState.wareHouse) &&
      warehouse.findIndex(
        (x) =>
          x.toLocaleLowerCase() ==
          clientDefinitionState.wareHouse.toLocaleLowerCase()
      );
    index = index === -1 ? true : false;
    return index;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const clientStatusToSave =
      clientDefinitionState.clientStatus.toLocaleLowerCase();
    // const logoImageFileToSave = clientDefinitionState.file;
    const logoImageFileToSave = clientDefinitionState.logoImageFile;
    const landingImageFileToSave = clientDefinitionState.landingImageFile;
    const clientFormData = new FormData();
    if (!location.state.update) {
      clientFormData.append("image", logoImageFileToSave);
      clientFormData.append("landingImage", landingImageFileToSave);
      let newclient = {
        name: clientDefinitionState.clientName || (clientname && clientname[0]),
        status: clientStatusToSave,
        text: clientDefinitionState.text,
        photo: logoImageFileToSave.name,
        landingPhoto: landingImageFileToSave.name,
        orderApprovel: clientDefinitionState.orderapprovel,
        payment: clientDefinitionState.payment,
        guestAccess: clientDefinitionState.guestaccess,
        extension: clientDefinitionState.extension,
        warehouse:
          clientDefinitionState.wareHouse || (warehouse && warehouse[0]),
        delivery: parseFloat(clientDefinitionState.deliverycharges).toFixed(2),
        nopricing: clientDefinitionState.nopricing,
        pricingformfeed: clientDefinitionState.pricingFeed,
        maintainPricing: clientDefinitionState.maintainPrice,
        isCategoryEnabled: clientDefinitionState.isCategoryEnabled,
        isDeliveryEnabled: clientDefinitionState.isDeliveryEnabled,
      };
      for (var key in newclient) {
        clientFormData.append(key, newclient[key]);
      }

      addClient(
        clientFormData,
        history,
        clientDefinitionState.currentCategories,
        clientDefinitionState.categoriesImages,
        location
      );
    } else {
      if (
        checkClientNameExitsOnOstendo() ||
        checkClientWarehouseExitsOnOstendo()
      ) {
        return;
      } else {
        let updateClientData = {
          name: clientDefinitionState.clientName,
          status: clientDefinitionState.clientStatus,
          text: clientDefinitionState?.text?.length
            ? clientDefinitionState.text
            : "",
          warehouse: clientDefinitionState.wareHouse,
          orderApprovel: clientDefinitionState.orderapprovel,
          extension: clientDefinitionState.extension,
          payment: clientDefinitionState.payment,
          guestAccess: clientDefinitionState.guestaccess,
          nopricing: clientDefinitionState.nopricing,
          pricingformfeed: clientDefinitionState.pricingFeed,
          maintainPricing: clientDefinitionState.maintainPrice,
          delivery:
            parseFloat(clientDefinitionState.deliverycharges).toFixed(2) || parseFloat(0.00),
          isCategoryEnabled: clientDefinitionState.isCategoryEnabled,
          isDeliveryEnabled: clientDefinitionState.isDeliveryEnabled,
        };
        if (logoImageFileToSave instanceof File) {
          updateClientData.photo = logoImageFileToSave.name;
          clientFormData.append("image", logoImageFileToSave);
        }

        if (landingImageFileToSave instanceof File) {
          updateClientData.landingPhoto = landingImageFileToSave.name;
          clientFormData.append("landingImage", landingImageFileToSave);
        }
        for (let key in updateClientData) {
          clientFormData.append(key, updateClientData[key]);
        }
        const clientId = JSON.parse(localStorage.getItem("currClient"))?._id;
        if (clientDefinitionState.selectedcategoryAllDeleted) {
          updateClient(
            clientFormData,
            clientId,
            history,
            clientDefinitionState.currentCategories,
            location
          );
        } else {
          const categoriesToUpdate = clientDefinitionState.currentCategories
            .length
            ? clientDefinitionState.currentCategories
            : // : clientDefinitionState.categoryArray.filter((cat) => cat._id);
            currCategory.filter((cat) => cat._id);
          updateClient(
            clientFormData,
            clientId,
            history,
            categoriesToUpdate,
            clientDefinitionState.categoriesImages,
            location
          );
        }

        setClientDefinitionState({
          ...clientDefinitionState,
          selectedcategoryAllDeleted: false,
        });
        clearCurrentClient();
        clearCategory();
      }
    }
  };
  return (
    <form onSubmit={onSubmit} encType="multipart/form-data">
      {loading && <img src={spinner} className="spinner" />}
      {!location.state.view && (
        <button type="submit" className="btn button mr-3">
          {messages.button.save}
        </button>
      )}
      <button type="button" className="btn button mr-3" onClick={onExit}>
        {!location.state.view
          ? messages.button.exitWotoutSave
          : messages.button.exit}
      </button>
      <div className="card border-dark p-5 mt-3">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group row ">
              <label htmlFor="name" className="col-sm-6 col-form-label label">
                {messages.label.clientName}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3 ">
                <select
                  className={classnames("form-control sel-w selectList", {
                    "is-invalid":
                      error?.name || checkClientNameExitsOnOstendo(),
                  })}
                  value={clientDefinitionState.clientName}
                  onChange={(e) =>
                    updateClientDefinitionState("clientName", e.target.value)
                  }
                  disabled={location.state.view}
                >
                  {clientname.length &&
                    clientname.map((client) => (
                      <option value={client} key={client}>
                        {client}
                      </option>
                    ))}
                </select>
                <small>{error?.name}</small>
                <small>
                  {checkClientNameExitsOnOstendo() && "ClientName Is Incorrect"}
                </small>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group row ">
              <label htmlFor="name" className="col-sm-6 col-form-label label">
                {messages.label.clientStatus}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3">
                <select
                  id="selctClientStatus"
                  className={classnames("form-control selectList", {
                    "is-invalid": error?.status,
                  })}
                  onChange={(e) =>
                    updateClientDefinitionState("clientStatus", e.target.value)
                  }
                  value={clientDefinitionState.clientStatus}
                  disabled={location.state.view}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
                <small>{error?.status}</small>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group row ">
              <label htmlFor="name" className="col-sm-6 col-form-label label ">
                {messages.label.mapToWarehouse}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3">
                <select
                  id="selctClientWareHouse"
                  className={classnames("form-control selectList", {
                    "is-invalid":
                      error?.warehouse || checkClientWarehouseExitsOnOstendo(),
                  })}
                  value={clientDefinitionState.wareHouse}
                  onChange={(e) =>
                    updateClientDefinitionState("wareHouse", e.target.value)
                  }
                  disabled={location.state.view}
                >
                  {warehouse.length &&
                    warehouse.map((whouse) => (
                      <option value={whouse} key={whouse}>
                        {whouse}
                      </option>
                    ))}
                </select>
                <small>{error?.warehouse}</small>
                <small>
                  {checkClientWarehouseExitsOnOstendo() &&
                    "Warehouse Is Incorrect"}
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group row ">
              <label htmlFor="name" className="col-sm-6 col-form-label label">
                {messages.label.logoForLanding}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3 ">
                <div className={!error?.image && "custom-file-border"}>
                  <div className="custom-file ">
                    <input
                      type="file"
                      name="files"
                      accept=".png,.jpeg,.jpg"
                      id="inputGroupFile01"
                      className={classnames("custom-file-input", {
                        "is-invalid": error?.image,
                      })}
                      encType="multipart/form-data"
                      onChange={(e) =>
                        onFileChange(
                          "logoImageFile",
                          "logoImageFileLabel",
                          e.target.files[0]
                        )
                      }
                      disabled={location.state.view}
                    />

                    {/* <small>{error?.image}</small> */}
                    <label
                      className="custom-file-label"
                      htmlFor="inputGroupFile01"
                      style={{ fontSize: "12px" }}
                    >
                      {/* {clientDefinitionState.fileLabel} */}
                      {clientDefinitionState.logoImageFileLabel}
                    </label>
                  </div>
                </div>
                <small>{error?.image}</small>
              </div>
            </div>

            <div className="form-group row ">
              <label htmlFor="name" className="col-sm-6 col-form-label label">
                {messages.label.imageForLanding}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3 ">
                <div className={!error?.landingImage && "custom-file-border"}>
                  <div className="custom-file ">
                    <input
                      type="file"
                      name="files"
                      accept=".png,.jpeg,.jpg"
                      id="inputGroupFile01"
                      className={classnames("custom-file-input", {
                        "is-invalid": error?.landingImage,
                      })}
                      encType="multipart/form-data"
                      onChange={(e) =>
                        onFileChange(
                          "landingImageFile",
                          "landingImageFileLabel",
                          e.target.files[0]
                        )
                      }
                      disabled={location.state.view}
                    />

                    {/* <small>{error?.image}</small> */}
                    <label
                      className="custom-file-label"
                      htmlFor="inputGroupFile01"
                      style={{ fontSize: "12px" }}
                    >
                      {clientDefinitionState.landingImageFileLabel}
                    </label>
                  </div>
                </div>
                <small>{error?.landingImage}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="name" className="col-sm-6 col-form-label label">
                {messages.label.extensionForLanding}
              </label>
              <div className="col-sm-6 px-0 px-md-3 px-sm-3">
                <input
                  type="text"
                  value={clientDefinitionState.extension}
                  disabled={location.state.view}
                  className={classnames("form-control ", {
                    "is-invalid": error?.extension,
                  })}
                  onChange={(e) =>
                    updateClientDefinitionState("extension", e.target.value)
                  }
                />
                <small>{error?.extension}</small>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div>
            <label htmlFor="name" className="col-form-label px-3 ">
              {messages.label.textForLandingPage}
            </label>
          </div>
          <div
            className={classnames("form-control textarea-border", {
              "is-invalid": error?.text,
            })}
          >
            <RichTextEditorComponent
              height={450}
              enabled={!location.state.view}
              enablePersistence={false}
              value={clientDefinitionState.text}
              toolbarSettings={toolbarSettings}
              change={(e) => {
                updateClientDefinitionState("text", e.value);
              }}
              paste={(e) => e.preventDefault()}
              drag={(e) => e.preventDefault()}
            >
              <Inject
                services={[
                  Toolbar,
                  // Image,
                  Link,
                  HtmlEditor,
                  QuickToolbar,
                  Table,
                ]}
              />
            </RichTextEditorComponent>
          </div>
          <small>{error?.text}</small>
        </div>

        <div className="row">
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">
                {messages.label.enableOrderApproval}
              </label>
              <div className="col-5">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    updateClientDefinitionState(
                      "orderapprovel",
                      e.target.checked
                    )
                  }
                  defaultChecked={currentClient.orderApprovel}
                  disabled={location.state.view}
                  style={{ marginTop: "10px" }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">
                {messages.label.enablePayments}
              </label>
              <div className="col-5">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    updateClientDefinitionState("payment", e.target.checked)
                  }
                  defaultChecked={currentClient.payment}
                  disabled={location.state.view}
                  style={{ marginTop: "10px" }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-2">
            <div className="form-group row ">
              <label className="col-7 col-form-label label">
                {messages.label.enableGuestAccess}
              </label>
              <div className="col-5">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    updateClientDefinitionState("guestaccess", e.target.checked)
                  }
                  defaultChecked={currentClient.guestAccess}
                  disabled={location.state.view}
                  style={{ marginTop: "10px" }}
                />
              </div>
            </div>
          </div>
        </div>

        <AddClientPricingOption
          changePricingOption={changePricingFeed}
          labelpricingFromfeed={messages.label.pricingFromfeed}
          labelmaintainPricing={messages.label.maintainPricing}
          labelnoPricing={messages.label.noPricing}
          pricingFeedCheck={clientDefinitionState.pricingFeed}
          maintainPricingCheck={clientDefinitionState.maintainPrice}
          noPricingCheck={clientDefinitionState.nopricing}
          pricingOptionDisable={location.state.view}
        />

        <AddClientDelivery
          changeDeliveryCheck={changeIsDelived}
          changeDeliveryValue={updateClientDefinitionState}
          showDelivery={clientDefinitionState.showdelivery}
          deliveryDisabled={location.state.view}
          deliveryLabel={messages.label.deliveryCharges}
          deliveryError={error?.delivery}
          deliveryValue={clientDefinitionState.deliverycharges}
        />

        <AddClientCategory
          changeCategoryValue={updateClientDefinitionState}
          categoryCheckChange={changeCategoryIsShown}
          addCategoryonArray={onAddCategory}
          changeCategoryFromCategoryArray={changeCategory}
          categoryImageFileChange={onCategoryFileChange}
          categoryCheckLabel={messages.label.enableCategoryDisplay}
          categoryCheck={clientDefinitionState.showcategory}
          categoryDisable={location.state.view}
          categoryNameError={clientDefinitionState?.categoryNameError}
          categoryError={clientDefinitionState?.categoryError}
          categoryName={clientDefinitionState.categoryName}
          categoryImageError={clientDefinitionState?.categoryImageError}
          categoryImageLabel={clientDefinitionState.categoryImageLabel}
          categoriesLabel={messages.label.categories}
          currCategoryArray={localStorage.getItem("currCategory") ? JSON.parse((localStorage.getItem("currCategory"))) : []}
          categoryArrayForOptions={clientDefinitionState.categoryArray}
        />
      </div>
    </form>
  );
};

export default AddClientForm;
