import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { orderBy } from "lodash";

const UserTable = ({ users, currtUser, onChange, changeOrderBy, orderBy }) => {
  const history = useHistory();
  const [userState, setUserState] = useState({
    order: "asc",
    users: [],
  });

  useEffect(() => {
    setUserState({
      ...userState,
      users: users || [],
    });
  }, [users]);

  const messages = {
    heading: {
      user: "User",
      role: "Role",
      client: "Client",
      status: "Status",
    },
    button: {
      edit: "Edit",
    },
  };

  const onEdit = (user) => {
    history.push({
      pathname: "/users/addusers",
      state: { update: true },
    });
    currtUser(user);
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <table className="table table-sm ">
        <thead className="thead-dark">
          <tr>
            <th>
              <button
                type="button"
                onClick={(e) => {
                  onChange("sortBy", "name");
                  changeOrderBy();
                }}
                className="sort-table-btn"
              >
                {messages.heading.user}
              </button>
            </th>
            <th>
              <button
                type="button"
                onClick={(e) => {
                  onChange("sortBy", "role");
                  changeOrderBy();
                }}
                className="sort-table-btn"
              >
                {messages.heading.role}
              </button>
            </th>
            <th>
              <button
                type="button"
                onClick={(e) => {
                  onChange("sortBy", "userstatus");
                  changeOrderBy();
                }}
                className="sort-table-btn"
              >
                {messages.heading.status}
              </button>
            </th>
            <th>
              <button
                type="button"
                onClick={(e) => {
                  onChange("sortBy", "clientName");
                  changeOrderBy();
                }}
                className="sort-table-btn"
              >
                {messages.heading.client}
              </button>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {userState.users.map((user) => (
            <tr key={user._id}>
              <td>{user.name}</td>
              <td>{user.role}</td>
              <td>{user.userstatus}</td>
              <td>{user.clientName}</td>
              <td>
                <button className="btn button" onClick={() => onEdit(user)}>
                  {messages.button.edit}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
