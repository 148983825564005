import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import AdminContext from "../../../contextAPI/AdminContext";
import UserDetials from "./UserDetials";
import UserDeliveryDetial from "./UserDeliveryDetial";
const AddUserFormContainer = ({clientname,productManager}) => {
  const history = useHistory();
  const location = useLocation();
  // console.log(clientname,productManager);

  const {
    getClientName,
    addUsers,
    currentUser,
    updateUser,
    error,
    // clientname,
    getProductManager,
    // productManager,
    loading,
  } = useContext(AdminContext);
  let exits = false;
  const [userDefinitionState, setUserDefinitionState] = useState({
    login: "",
    name: "",
    email: "",
    password: "",
    role: "buyer",
    clientName: "",
    phone: "",
    address1: "",
    address2: "",
    postcode: "",
    organization: "",
    town: "",
    state: "",
    status: "active",
    dname: "",
    demail: "",
    dphone: "",
    daddress1: "",
    daddress2: "",
    dpostcode: "",
    dstate: "",
    dtown: "",
    dorganization: "",
    productManager: [],
    clientNameExits: false,
  });
  const updateUserDefinitionState = (field, value) => {
    setUserDefinitionState({
      ...userDefinitionState,
      [field]: value,
    });
  };

  const changeDropdown = () => {
    let client =
      // clientname &&
      // clientname.clients &&
      clientname?.clients?.find(
        (client) => client.name == userDefinitionState.clientName
      );
    // if (client) {
      if (client && productManager.length !== 0) {
      let currentWarehouseMangers = productManager.filter(
        (x) => x.warehouse == client.warehouse
      );
      currentWarehouseMangers = currentWarehouseMangers.map(
        (x) => x.productManager
      );
      updateUserDefinitionState("productManager", currentWarehouseMangers);
    }
  };
  useEffect(() => {
    changeDropdown();
    setTimeout(() => {
      changeDropdown();
    }, 1000);
  }, [userDefinitionState.clientName]);

  const setCurrentUserDetails = () => {
    const userState = { ...currentUser };
    // console.log(currentUser);
    setUserDefinitionState({
      ...userDefinitionState,
      login: userState.login,
      name: userState.name,
      email: userState.email,
      status: userState.userstatus,
      password: userState.password,
      clientName: userState.clientName,
      address1: userState.address1,
      address2: userState.address2,
      postcode: userState.postcode,
      phone: userState.phone,
      role: userState.role,
      organization: userState.organization,
      state: userState.state,
      town: userState.town,
    });
  };

  const setCurrentUserAndDeliveryDetails = () => {
    const userState = { ...currentUser };
    const delivery = { ...userState.delivery };
    setUserDefinitionState({
      ...userDefinitionState,
      login: userState.login,
      name: userState.name,
      email: userState.email,
      status: userState.userstatus,
      password: userState.password,
      clientName: userState.clientName,
      address1: userState.address1,
      address2: userState.address2,
      postcode: userState.postcode,
      phone: userState.phone,
      role: userState.role,
      organization: userState.organization,
      state: userState.state,
      town: userState.town,
      dname: delivery.name,
      dorganization: delivery.organization,
      dtown: delivery.town,
      dstate: delivery.state,
      dpostcode: delivery.postcode,
      daddress1: delivery.address1,
      daddress2: delivery.address2,
      dphone: delivery.phone,
      demail: delivery.email,
    });
  };


  useEffect(async () => {
    // await getClientName();
    // await getProductManager();
    
    if (currentUser?._id) {
      if (currentUser?.delivery?.name) {
        setCurrentUserAndDeliveryDetails();
      } else {
        setCurrentUserDetails();
      }
      changeDropdown();
    }
  }, [currentUser,clientname,productManager]);

  const checkNameisExits = (index) => {
    exits = index;
  };

  const updateUserNameBasedOnClientWarehouse = async (
    userName,
    userRole,
    clientName
  ) => {
    if (userRole === "buyer") {
      return userName;
    } else {
      let client =
        clientname &&
        clientname.clients &&
        clientname.clients.find((client) => client.name == clientName);
      if (client) {
        let currentWarehouseMangers = productManager.filter(
          (x) => x.warehouse == client.warehouse
        );
        return _.size(currentWarehouseMangers) > 0 ? userName : "";
      }
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!location.state.update) {
      const newUser = {
        clientName: userDefinitionState.clientName,
        login: userDefinitionState.login.trim(),
        name: userDefinitionState.name,
        email: userDefinitionState.email,
        password: userDefinitionState.password,
        role: userDefinitionState.role,
        address1: userDefinitionState.address1,
        address2: userDefinitionState.address2,
        postcode: userDefinitionState.postcode,
        phone: userDefinitionState.phone,
        organization: userDefinitionState.organization,
        state: userDefinitionState.state,
        town: userDefinitionState.town,
        userstatus: userDefinitionState.status,
        dname: userDefinitionState.dname,
        dorganization: userDefinitionState.dorganization,
        dtown: userDefinitionState.dtown,
        dstate: userDefinitionState.dstate,
        dpostcode: userDefinitionState.dpostcode,
        daddress1: userDefinitionState.daddress1,
        daddress2: userDefinitionState.daddress2,
        dphone: userDefinitionState.dphone,
        demail: userDefinitionState.demail,
      };
      addUsers(newUser, history,location);
    } else {
      if (exits) {
        return;
      } else {
        let userName = await updateUserNameBasedOnClientWarehouse(
          userDefinitionState.name,
          userDefinitionState.role,
          userDefinitionState.clientName
        );
        const newUser = {
          clientName: userDefinitionState.clientName,
          login: userDefinitionState.login.trim(),
          name: userName,
          email: userDefinitionState.email,
          password: userDefinitionState.password,
          role: userDefinitionState.role,
          address1: userDefinitionState.address1,
          address2: userDefinitionState.address2,
          postcode: userDefinitionState.postcode,
          phone: userDefinitionState.phone,
          town: userDefinitionState.town,
          state: userDefinitionState.state,
          organization: userDefinitionState.organization,
          userstatus: userDefinitionState.status,
          dname: userDefinitionState.dname,
          dorganization: userDefinitionState.dorganization,
          dtown: userDefinitionState.dtown,
          dstate: userDefinitionState.dstate,
          dpostcode: userDefinitionState.dpostcode,
          daddress1: userDefinitionState.daddress1,
          daddress2: userDefinitionState.daddress2,
          dphone: userDefinitionState.dphone,
          demail: userDefinitionState.demail,
        };
        const currentUserId = JSON.parse(localStorage.getItem("currUser"))._id;
        updateUser(newUser, currentUserId, history,location);
      }
    }
  };

  useEffect(() => {
    if (userDefinitionState.role == "buyer") {
      setUserDefinitionState({
        ...userDefinitionState,
        name: "",
      });
    } else if (
      userDefinitionState.role == "approver" &&
      currentUser.name == userDefinitionState.name &&
      userDefinitionState.name.length !== 0
    ) {
      setUserDefinitionState({
        ...userDefinitionState,
        name: "",
      });
    }
  }, [userDefinitionState.role]);
  return (
    <div>
      {userDefinitionState && (
        <form onSubmit={onSubmit}>
          {userDefinitionState && (
            <div id="accordion" className="mt-3">
              <UserDetials
                error={error}
                checkNameisExitsonDB={checkNameisExits}
                changeInput={updateUserDefinitionState}
                login={userDefinitionState.login}
                productManager={userDefinitionState.productManager || []}
                password={userDefinitionState.password}
                role={userDefinitionState.role}
                name={userDefinitionState.name}
                clientName={userDefinitionState.clientName}
                organization={userDefinitionState.organization}
                phone={userDefinitionState.phone}
                email={userDefinitionState.email}
                address1={userDefinitionState.address1}
                address2={userDefinitionState.address2}
                town={userDefinitionState.town}
                state={userDefinitionState.state}
                postcode={userDefinitionState.postcode}
                userStatus={userDefinitionState.status}
                clientNameExits={userDefinitionState.clientNameExits}
                clients={clientname.clients || []}
                currentUser={currentUser}
              />

              <UserDeliveryDetial
                error={error}
                changeInput={updateUserDefinitionState}
                dname={userDefinitionState?.dname}
                dorganization={userDefinitionState?.dorganization}
                daddress1={userDefinitionState?.daddress1}
                daddress2={userDefinitionState?.daddress2}
                dtown={userDefinitionState?.dtown}
                dstate={userDefinitionState?.dstate}
                dpostcode={userDefinitionState?.dpostcode}
                dphone={userDefinitionState?.dphone}
                demail={userDefinitionState?.demail}
              />
            </div>
          )}

          <input
            type="submit"
            value={!currentUser?._id ? "Submit" : "Update"}
            className="btn button"
            style={{ float: "right" }}
          />
        </form>
      )}
    </div>
  );
};

export default AddUserFormContainer;
