import React from "react";

const Label = ({ className, msg, htmlFor }) => {
  return (
    <label htmlFor={htmlFor} className={className}>
      {msg}
    </label>
  );
};

export default Label;
