import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import moment from "moment";

const Search = ({
  getSearchValueFromTable,
  serachOption,
  searchField,
  searchForSkus=false,
  searchValue = "",
  from = "",
  to= "",
  setSearchingValues,
}) => {
  const [searchState, setSearchState] = useState({
    searchField: searchField, searchValue: searchValue, from, to
  })

  const onChange = async (field, value) => {
    setSearchState({
      ...searchState,
      [field]: value,
    })
    if (field === "searchField" && value === "_") {
      searchState.searchField = "";
      searchState.searchValue = "";
      searchState.from = "";
      searchState.to = "";
      setSearchingValues(searchState)
      await getSearchValueFromTable();
    }
  }


  useEffect(() => {
    if (searchState.searchField == "_") {
      setSearchState({
        ...searchState,
        searchValue:"",
        from:"",
        to:"",
      })
    }
  }, [searchState.searchField == "_"]);

  console.log(searchState);
  let searchForOrderByDate1=  searchState.searchField == "Date" ? true : false


  return (
    <>
      <div
        className={`${!searchForOrderByDate1 && !searchForSkus
            ? "col-md-4"
            : searchForSkus
              ? "col-md-3 mb-2"
              : "col-md-2"
          }`}
      >
        <select
          className="form-control mb-1 selectList"
          onChange={(e) => {
            // onChnageByUser("searchField", e.target.value);
            onChange("searchField", e.target.value);

          }}
          value={searchState.searchField}
        >
          <option value="_">Search All</option>
          {serachOption?.map((serachoption) => (
            <option value={serachoption} key={serachoption}>
              {serachoption}
            </option>
          ))}
        </select>
      </div>
      {!isEmpty(searchState.searchField) && searchState.searchField !== "_" && (
        <>
          {!searchForOrderByDate1 ? (
            <div className={`${!searchForSkus ? "col-md-4" : "col-md-3"}`}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder={`Enter ${searchState?.searchField}`}
                  aria-describedby="basic-addon2"
                  onChange={(e) =>
                    // onChnageByUser("searchValue", e.target.value)
                    onChange("searchValue", e.target.value)
                  }
                  value={searchState.searchValue}
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text selectList"
                    id="basic-addon2"
                    style={{ cursor: "pointer" }}
                    // onClick={getSearchValueFromTable}
                    onClick={() => {
                      // onChnageByUser("page", 1);
                      setSearchingValues(searchState)
                      getSearchValueFromTable(true);
                    }}
                  >
                    <i className="fas fa-search"></i>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="col-md-5">
                <div className="row form-group">
                  <div className="col-md-2 mt-2">
                    <label htmlFor="From" style={{ fontWeight: "bold" }}>
                      From
                    </label>
                  </div>
                  <div className="col-md-10">
                    <input
                      type="date"
                      className="form-control mb-3"
                      placeholder="Dates"
                      onChange={(e) =>
                        // onChnageByUser("from", e.target.valueAsDate)
                        onChange("from", e.target.valueAsDate)
                      }
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      value={moment(searchState.from).format("YYYY-MM-DD")  }
                      max={moment().subtract(1, "day").format("YYYY-MM-DD")}

                    />
                    {/* <DatePicker
                    //  maxDate="Wed Jun 23 2099 00:00:00 GMT+0500 (Pakistan Standard Time)"  
                      dayPlaceholder="dd" 
                      monthPlaceholder="mm"
                      yearPlaceholder="yyyy"
                      onChange={(e)=>{
                        console.log(e);
                      }} /> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="row form-group">
                  <div className="col-md-2 mt-2">
                    <label htmlFor="From" style={{ fontWeight: "bold" }}>
                      To
                    </label>
                  </div>
                  <div className="col-md-10">
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Dates"
                        aria-describedby="basic-addon2"
                        onChange={(e) =>
                          // onChnageByUser("to", e.target.valueAsDate)
                          onChange("to", e.target.valueAsDate)
                        }
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        value={moment(searchState.to).format("YYYY-MM-DD")  }


                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text "
                          id="basic-addon2"
                          style={{ cursor: "pointer" }}
                          //onClick={getSearchValueFromTable}
                          onClick={() => {
                            setSearchingValues(searchState)
                            getSearchValueFromTable(true);
                          }}
                        >
                          <i className="fas fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Search;
