import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { isEmpty } from "lodash";


import Navbar from "../utilsComponent/Navbar";
import AdminContext from "../../contextAPI/AdminContext";
import Spinner from "../utilsComponent/Spinner";
import ALerts from "../utilsComponent/ALerts";
import UserTable from "./UserTable";
import UserUploadForm from "./UserUploadForm";
import headers from "../../csvHeader/userHeader";
import PaginationServerSide from "../utilsComponent/PaginationServerSide";
import Search from "../utilsComponent/Search";

const User = () => {
  const history = useHistory();
  const {
    getAllUsers,
    users,
    currtUser,
    msg,
    clearMsg,
    loading,
    clearAllUsers,
    userPagination,
    userPaginationAndSearchingState
  } = useContext(AdminContext);

  let [userState, setUserState] = useState({
    limit: userPagination?.limit ? (userPagination?.limit) : 20,
    page: userPagination?.page ? userPagination?.page : 1,
    limitOption: [20, 50, "All"],
    serachOption: ["UserName", "UserStatus", "UserRole", "ClientName"],
    searchField: userPagination?.searchField ? userPagination?.searchField : "_",
    searchValue: userPagination?.searchValue ? userPagination?.searchValue : "",
    sortBy: "name",
    serachUsersNotBySearchValue: true
  });
  const [orderBy, setOrderBy] = useState("asc");

  const setSearchingValues = (value) => {
    userState = { ...userState, searchField: value?.searchField, searchValue: value?.searchValue, page: 1, serachUsersNotBySearchValue: false }
    setUserState({
      ...userState,
      searchField: value?.searchField,
      searchValue: value?.searchValue,
      page: 1,
      serachUsersNotBySearchValue: false
    })
  }

  const messages = {
    button: {
      goback: "Home",
      addUser: "Add New User",
      download: "Download To CSV",
    },
    label: {
      show: "Show",
      entries: "entries",
    },
  };

  const onChange = async (field, value) => {
    setUserState({
      ...userState,
      [field]: value,
      page: field === "limit" ? 1 : userState.page,
    });
    if (field === "searchField" && value === "_") {
      userState.searchField = "";
      userState.searchValue = "";
     await getSearchValue();
    }

  };
  const changeOrderBy = () => {
    if (orderBy === "asc") {
      setOrderBy("desc");
    } else {
      setOrderBy("asc");
    }
  };

  const callbackFunctionFromPagination = (page) => {
    setUserState({
      ...userState,
      page: parseInt(page),
    });
  };

  const goToAddUser = () => {
    localStorage.removeItem("currClient");
    const { page, limit, searchField, searchValue } = userState
    history.push({
      pathname: "/users/addusers",
      state: { update: false },
    });
  };

  useEffect(async () => {
    const { page, limit, searchField, searchValue } = userState
    await userPaginationAndSearchingState(page, limit, searchField, searchValue)
  }, [userState.page, userState.limit, userState.searchField, userState.searchValue])

  useEffect(() => {
    if (userState.searchField == "_") {
      onChange("searchValue", "");
    }
  }, [userState.searchField == "_"]);
  

  useEffect(async () => {
    if (userState?.serachUsersNotBySearchValue) {
      await getSearchValue();
    }
  }, [
    userState.limit,
    userState.page,
    userState.sortBy,
    orderBy,
  ]);


  const csvReport = {
    data: users.users || [],
    headers: headers,
    filename: "User.csv",
  };

  const getSearchValue = async (setPageNumberOneIfWeSearchData) => {
    let { limit, page, searchValue, searchField, sortBy } = userState;
    page = setPageNumberOneIfWeSearchData ? 1 : page
    let searchFieldForBackend = "";
    if (searchField === "UserName") {
      searchFieldForBackend = "name";
    }
    if (searchField === "UserStatus") {
      searchFieldForBackend = "userstatus";
    }
    if (searchField === "UserRole") {
      searchFieldForBackend = "role";
    }
    if (searchField === "ClientName") {
      searchFieldForBackend = "clientName";
    }
    await getAllUsers(
      limit,
      page,
      searchFieldForBackend,
      searchValue,
      sortBy,
      orderBy
    );
    userState = { ...userState, serachUsersNotBySearchValue: true }
    setUserState({
      ...userState,
      serachUsersNotBySearchValue: true
    })
  };

  const usersSearch = users.users || [];

  return (
    <div>
      <Navbar></Navbar>
      <div className="container-fluid mb-4 px-5">
        <h1 className="display-4">Users</h1>
        <Link
          to="/dashboard"
          className="btn button"
          onClick={() => {
            clearAllUsers();
            clearMsg();
          }}
        >
          {messages.button.goback}
        </Link>
        {msg && <ALerts msg={msg}></ALerts>}
        <div className="row mt-2">
          <div className="col-md-4 mb-2">
            <button className="btn button btn-block" onClick={goToAddUser}>
              {messages.button.addUser}
            </button>
          </div>
          <div className="col-md-4 mb-2">
            {/* <button className="btn button btn-block"> */}
            <CSVLink
              {...csvReport}
              // style={{ color: "#fff", textDecoration: "none" }}
              className="btn button btn-block"
            >
              <span className="csvLink"> {messages.button.download}</span>
            </CSVLink>
            {/* </button> */}
          </div>
          <UserUploadForm />
        </div>

        <div className="row">
          <Search
            getSearchValueFromTable={getSearchValue}
            serachOption={userState?.serachOption}
            searchField={userState.searchField}
            searchValue={userState.searchValue}
            setSearchingValues={setSearchingValues}
          />
        </div>

        <div className="user-table-show-limit nmt-10">
          <div className="show-search"></div>
          <div className="show-limit">
            <p className="pt-3" style={{ fontWeight: "bold" }}>
              {messages.label.show}
            </p>
            <select
              name="range"
              className="form-control mx-1 selectList"
              style={{ width: "70px" }}
              onChange={(e) => onChange("limit", e.target.value)}
              value={userState.limit}
            >
              {userState.limitOption.map((limitValue) => (
                <option key={limitValue} value={limitValue}>
                  {limitValue}
                </option>
              ))}
            </select>
            <p className="pt-3" style={{ fontWeight: "bold" }}>
              {messages.label.entries}
            </p>
          </div>
        </div>

        {loading && isEmpty(users) ? (
          <Spinner />
        ) : (
          <div>
            <>
              {loading && <Spinner />}
              <div className="userTable">
                <UserTable
                  users={usersSearch || []}
                  currtUser={currtUser}
                  history={history}
                  orderBy={userState.orderBy}
                  onChange={onChange}
                  changeOrderBy={changeOrderBy}
                />
              </div>
            </>
            <PaginationServerSide
              callBack={callbackFunctionFromPagination}
              total={users.totalPages}
              initialPage={userState.page - 1}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default User;
